import { Box } from "@mui/material";
import IssueTab from "components/IssuesTabs";
import IssuesComponent from "components/IssuesComponent";
import SettingsComponent from "components/SettingsComponent";
import FormsComponent from "components/FormsComponent";
import MediaComponent from "components/MediaComponent";
import DrawerComponent from "components/DrawerComponent";
import FilesComponent from "components/FilesComponent";
import SheetsComponent from "components/SheetsComponent";
import home from "assets/images/Home.svg";
import sheets from "assets/images/sheets.svg";
import file from "assets/images/file.svg";
import review from "assets/images/review.svg";
import issues from "assets/images/issues.svg";
import form from "assets/images/form.svg";
import photo from "assets/images/photo.svg";
import reports from "assets/images/reports.svg";
import settings from "assets/images/settings.svg";
import help from "assets/images/help.svg";
import AddSheetsComponent from "components/DrawerComponent/AddSheets";

//console.log("appRoutes projectID ::", global.projectId);
const appRoutes =(creditial , calledfrom)=> {
  //console.log(calledfrom ,'appRoutes creditial?.projectId ',creditial?.projectId);
  return [
  {
    index: true,
    sidebarProps: {
      displayText: "Home",
      icon: home,
    },
    state: "Sheets",
    element: (
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1, width: { sm: "100%" }, height: `calc(100vh - 113px)`, overflow: "hidden"}} >
          <SheetsComponent projectId={creditial?.projectId} tokenInfo={creditial} isPlan={true}/>
        </Box>
      </Box>
    ),
  },
  {
    subheading: "Menu",
  },
  {
    path: "/Dashboard",
    state: "Dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: home,
    },
  },
  {
    path: "/Sheets",
    element:  (
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1, width: { sm: "100%" }, height: `calc(100vh - 113px)`, overflow: "hidden", }} >
          <SheetsComponent projectId={creditial?.projectId} tokenInfo={creditial} isPlan={true}/>
        </Box>
      </Box>
    ),
    state: "Sheets",
    sidebarProps: {
      displayText: "Sheets",
      icon: sheets,
    },
  },
  {
    path: "/add_sheets",
    element: <AddSheetsComponent />,
    state: "addSheets",
  },
  {
    path: "/Files",
    element: <FilesComponent projectId={creditial?.projectId} tokenInfo={creditial} isPlan={false}/>,
    state: "Files",
    sidebarProps: {
      displayText: "Files",
      icon: file,
    },
  },
  {
    path: "/Review",
    state: "Review",
    sidebarProps: {
      displayText: "Review",
      icon: review,
      display: "none"
    },
  },
  {
    path: "/Issues",
    element: <IssuesComponent projectId={creditial?.projectId} tokenInfo={creditial} />,
    state: "Issues",
    sidebarProps: {
      displayText: "Issues",
      icon: issues,
    },
  },
  {
    path: "/Forms",
    element: <FormsComponent projectId={creditial?.projectId} tokenInfo={creditial} />,
    state: "Forms",
    sidebarProps: {
      displayText: "Forms",
      icon: form,
    },
  },
  {
    path: "/Media",
    state: "Media",
    element: <MediaComponent projectId={creditial?.projectId} tokenInfo={creditial} />,
    sidebarProps: {
      displayText: "Media",
      icon: photo,
    },
  },
  {
    subheading: "Others",
  },
  {
    path: "/Reports",
    state: "Reports",
    sidebarProps: {
      displayText: "Reports",
      icon: reports,
      display: "none"
    },
  },
  {
    path: "/Settings",
    element: <SettingsComponent projectId={creditial?.projectId} tokenInfo={creditial} />,
    state: "Settings",
    sidebarProps: {
      displayText: "Settings",
      icon: settings,
    },
  },
  {
    path: "/Help",
    //element: <ChangelogPage />,
    state: "Help",
    sidebarProps: {
      displayText: "Help and Support",
      icon: help,
      display: "none"
    },
  },
]};

export default appRoutes;