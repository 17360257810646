import { useState, useEffect, useRef } from "react";
import CloseIcon from "assets/images/close.svg";
import { Button, Dialog, TextField, MenuItem, Select } from "@mui/material";
import Paper from '@mui/material/Paper';
import { useDropzone } from "react-dropzone";
import WebViewer from "@pdftron/webviewer";
import errorRed from "assets/images/error-red.svg";
import { APRYSE_L_KEY } from "api";
import UploadIcon from "assets/images/upload.svg";
import { getPdfThumbnailFromWebViewer } from "components/SheetsComponent";
const PdfTemplateDetail = (props) => {
    const { onAccept, onCancel, templateOptions, sfObj, currentStep, setCurrentStep } = props;
    const [sfRecord, setSfRecord] = useState(sfObj ? JSON.parse(JSON.stringify(sfObj)) : null);
    const [selectedAttach, setSelectedAttach] = useState([]);
    const viewer = useRef();
    const [imageSrc, setImageSrc] = useState(null);
    const { getRootProps, getInputProps, inputRef } = useDropzone({ onDrop, multiple: false, accept: { 'application/pdf': ['.pdf'] } });
    const handleNextStep = (event) => {
        setCurrentStep((prev) => (prev + 1));
        setTimeout(() => {
            let fileUrl = URL.createObjectURL(selectedAttach[0].file)
            loadDocument(fileUrl);
        }, 500);
    };
    const handleBackStep = (event) => {
        setCurrentStep((prev) => (prev - 1));
    };
    function setVariables(_sfObj) {
        setSfRecord(JSON.parse(JSON.stringify(_sfObj)));
    }
    function onDrop(acceptedFiles) {
        setImageSrc(null);
        let _dropFiles = [];
        if (acceptedFiles.length > 0) {
            for (let doc of acceptedFiles) {
                _dropFiles.push({ attachId: null, fileName: doc.name, file: doc });
            }
            //simulateProgress();
        }
        setSelectedAttach(_dropFiles);
    };
    const loadDocument = async (fileUrl) => {
        if (viewer.current) {
            const disElement = [
                "leftPanelTabs",
                "zoomThumbOutButton",
                "thumbnailsSizeSlider",
                "toolbarGroup-View",
                "toolsHeader",
                "toolbarGroup-Annotate",
                "toolbarGroup-Shapes",
                "toolbarGroup-Insert",
                "toolbarGroup-Measure",
                "toolbarGroup-Edit",
                "toolbarGroup-FillAndSign",
                "toolbarGroup-Forms",
                "toolbarGroup-Redact",
                "annotationGroupButton",
                "linkButton",
                "annotationCommentButton",
                "thumbnailControl",
                "documentControl",
                "leftPanelResizeBar",
                "header"
            ];/* 
          "annotationDeleteButton",
          "annotationStyleEditButton"*/
            await WebViewer({
                fullAPI: true,
                path: "/webviewer/public",
                initialDoc:fileUrl ,
                disabledElements: disElement,
                licenseKey: APRYSE_L_KEY,
            },
                viewer.current
            ).then(async (instance) => {
                let pdfThumbnail = await getPdfThumbnailFromWebViewer('idddd', fileUrl, instance);
                setImageSrc(pdfThumbnail?.thumbnail);
            })
        }
    }
    return (
        <Dialog open={true} maxWidth="auto">
            <Paper sx={{ width: currentStep === 1 ? "548px" : "780px" }}>
                <div className="template_steps">
                    <div className="template_steps_head">
                        <div className="template_steps_right">
                            {!sfObj?.Id &&
                                <div className="template_steps_count">
                                    step {currentStep} of 2
                                </div>
                            }
                            <div className="template_steps_right_title">
                                {!sfObj?.Id ? 'Create PDF Template' : 'Edit Template Details'}
                            </div>
                        </div>
                        <div className="template_steps_left" onClick={onCancel}>
                            <img src={CloseIcon} alt="icons" />
                        </div>
                    </div>
                    <div className="template_steps_body" style={{ height: currentStep === 1 && "auto" }}>
                        {currentStep === 1 &&
                            <>
                                <div className="template_steps_body_text">
                                    Upload an existing PDF form. Any smart features within the PDF will be preserved.
                                </div>
                                <div className="upload_holder">
                                    <section className="dropZone_container">
                                        <div {...getRootProps({ className: "dropzone_2" })}>
                                            {!selectedAttach[0] &&
                                                <div className="upload_holder">
                                                    <input {...getInputProps()} />
                                                    <img src={UploadIcon} alt="upoad_icon" />
                                                    <p className="drag_drop">
                                                        Drag and Drop or{" "}
                                                        <span className="blue_upload">Choose File</span> to Upload
                                                    </p>
                                                    <p className="upload_p">File supported: pdf </p>
                                                </div>
                                            }
                                            {selectedAttach && selectedAttach[0] && selectedAttach[0].fileName &&
                                                <div className="upload_holder">
                                                    <input {...getInputProps()} />
                                                    <img src="/check-green.svg" alt="upoad_icon" style={{ width: "24px", height: "24px" }} />
                                                    <div style={{ color: "#0AA110", fontWeight: 400, fontSize: "14px" }}>
                                                        Upload Completed
                                                    </div>
                                                    <div style={{ color: "#000000", fontWeight: 600, fontSize: "14px" }}>
                                                        {selectedAttach[0].fileName}
                                                    </div>
                                                    <div style={{ color: "#346EF5", fontWeight: 400, fontSize: "14px" }}>Replace</div>
                                                </div>
                                            }
                                        </div>
                                        <aside>
                                        </aside>
                                    </section>
                                </div>
                            </>
                        }
                        {currentStep === 2 &&
                            <>

                                <div style={{ display: "flex" }}>
                                    <div className="template_steps_body_radio_1">
                                        {!sfObj?.Id &&
                                            <div className="template_steps_body_text" style={{ paddingLeft: "0px" }}>
                                                Kindly provide the essential details to continue
                                            </div>
                                        }
                                        <div style={{ marginBottom: "15px" }}>
                                            <div className="issue_label_name" style={{ paddingTop: "0px" }}>Template Name</div>
                                            <div>
                                                <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "356px", }, }}
                                                    inputProps={{ maxLength: 80 }}
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    className={!sfRecord.Name ? "Searchbar-field-red" : "Searchbar-field"}
                                                    autoComplete="off"
                                                    required
                                                    value={sfRecord?.Name ? sfRecord?.Name : ""}
                                                    onChange={(e) => {
                                                        let fff = sfRecord;
                                                        fff.Name = e?.target?.value;
                                                        setVariables(fff);
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key.toLowerCase() != "tab") {
                                                            event.stopPropagation();
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {!sfRecord.Name &&
                                                <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                                                    <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> This is required. Please input an Template Name.
                                                </div>
                                            }
                                        </div>
                                        <div style={{ marginBottom: "15px" }}>
                                            <div className="issue_label_name" style={{ paddingTop: "0px" }}>Template Type</div>
                                            <div>
                                                <Select id="type" style={{ height: "37px", width: "356px", color: "#505050" }}
                                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { borderColor: !sfRecord.Template_Category__c && "red" } }}
                                                    value={sfRecord?.Template_Category__c ? sfRecord?.Template_Category__c : ""}
                                                    onChange={(event) => {
                                                        let fff = sfRecord;
                                                        fff.Template_Category__c = event?.target?.value;
                                                        setVariables(fff);
                                                    }}>
                                                    {templateOptions?.checkListOptions?.Template_Category__c?.options &&
                                                        templateOptions?.checkListOptions?.Template_Category__c?.options.map(
                                                            (opt, index) => (
                                                                <MenuItem key={opt.value} value={opt.value} >
                                                                    {opt.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                </Select>
                                            </div>
                                            {!sfRecord.Template_Category__c &&
                                                <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                                                    <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> This is required. Please select an Template Category.
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <div className="issue_label_name" style={{ paddingTop: "0px" }}>Description</div>
                                            <div>
                                                <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "63px", padding: "4px", width: "356px", }, }}
                                                    inputProps={{ maxLength: 255 }}
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    className="Searchbar-field"
                                                    autoComplete="off"
                                                    value={sfRecord?.Description__c ? sfRecord?.Description__c : ""}
                                                    onChange={(e) => {
                                                        let fff = sfRecord;
                                                        fff.Description__c = e?.target?.value;
                                                        setVariables(fff);
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key.toLowerCase() != "tab") {
                                                            event.stopPropagation();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ background: "#F8F8F8", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <div
                                            id="extractPDFcontainer"
                                            className="webviewer"
                                            ref={viewer}
                                            style={{ display: "none" }}
                                        />
                                        <div><img style={{ width: "336px", height: "500px", paddingTop: "20px", paddingBottom: "20px" }} src={imageSrc ? imageSrc : "/icon.svg"} alt="" /></div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="template_steps_footer">

                        {currentStep === 1 &&
                            <div className="template_steps_footer_button">
                                <Button className="custom_button cancel_button" onClick={onCancel}>Cancel</Button>
                                <Button className="custom_button apply_button" disabled={!selectedAttach} onClick={handleNextStep}>Next</Button>
                            </div>
                        }
                        {currentStep === 2 &&
                            <div className="template_steps_footer_button">
                                {!sfObj?.Id && <Button className="custom_button cancel_button" onClick={handleBackStep}>Back</Button>}
                                {sfObj?.Id && <Button className="custom_button cancel_button" onClick={onCancel}>Cancel</Button>}
                                <Button disabled={!sfRecord.Name || !sfRecord.Template_Category__c} className="custom_button apply_button" onClick={() => { onAccept(sfRecord, selectedAttach[0]) }}>
                                    {!sfObj?.Id ? 'Create Form Template' : 'Save Changes'}
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </Paper>
        </Dialog>
    );
}
export default PdfTemplateDetail;