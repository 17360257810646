import React, { useState, useEffect } from "react";
import downIcon from "assets/images/arrow_drop_down.svg";
import upIcon from "assets/images/up.svg";
import loaderIcon from "assets/images/loader.svg";
import checkIcon from "assets/images/check.svg";
import errorIcon from "assets/images/error-red.svg";
import file from "assets/images/file.svg";

import {
   IconButton,
   Collapse,
   Tooltip
} from "@mui/material";
import { RunningWithErrors} from "@mui/icons-material";


const ItemInQueueComponent = ({ heading , queueItems, onClose }) => {
   const [headingLabel , setHeading] = useState(heading ? heading : 'Uploading') ;
    const [open, setOpen] = useState(true);
    const [status, setStatus] = useState(true);
    useEffect(() => {
      //setUploadFile(queueItems);
      //console.log("useEffect queueItems 111", queueItems);
      const objIndex = queueItems?.findIndex((data) => ( data.processed !== true ));
      if (objIndex > -1) {
         setStatus(true);
      } else {
         setStatus(false);
      }
    }, [queueItems])
    const RenderListItem = ({ item }) => {
      return (
         <div key={item.id} className="queue-body-list-item">
            <div className={ "queue-body-list-item_label truncate" + (!item?.processed ?  " disable_ele" : "")}>
               <>
                  <img src={item.icon ? item.icon :file} alt="icon"/>&nbsp;
               </>
               <Tooltip title={item.label} placement="left" PopperProps={{ className: "version-action-tooltip" }} >
                  <>{item.label}</>
               </Tooltip>
            </div>
            <div className="queue-body-list-item_status">
               {item?.started && <>
                  {item?.processed && <>
                     {item?.completed && <>
                        <Tooltip title="Completed" placement="left" PopperProps={{ className: "version-action-tooltip" }} >
                           <img src={checkIcon} alt="checkIcon"/>
                        </Tooltip>
                     </>}
                     {item?.failed && <>
                        <Tooltip title="Failed" placement="left" PopperProps={{ className: "version-action-tooltip" }} >
                           <RunningWithErrors color="error" fontSize="16" />
                        </Tooltip>
                     </>}
                     {item?.cancelled && <>
                        <Tooltip title="Cancelled" placement="left" PopperProps={{ className: "version-action-tooltip" }} >
                           <img src={errorIcon} alt="failIcon"/>
                        </Tooltip>
                     </>}
                  </>}
                  {!item?.processed && <>
                     <Tooltip title="Processing" placement="left" PopperProps={{ className: "version-action-tooltip" }} >
                        <img src={loaderIcon} alt="processIcon" style={{animation : 'rotate360 0.5s linear infinite'}}/>
                     </Tooltip>
                  </>}
               </>}
               {!item?.started &&<>
                  <Tooltip title="Preparing" placement="left" PopperProps={{ className: "version-action-tooltip" }} >
                     <img src={loaderIcon} alt="processIcon" style={{animation : 'rotate360 0.5s linear infinite'}}/>
                  </Tooltip>
               </>}
            </div>
         </div>
      )
   };
   return (
      <div className="queue-body-modal">
         <div className="queue-body-header">
            <div className="queue-body-header_label">
               {headingLabel} ({queueItems?.length})
            </div>
            <div className="queue-body-header_action">
               <IconButton disableRipple className="btn" onClick={() => {setOpen(!open);}}>
                  {open ? <img src={downIcon} alt="downIcon"/> : <img src={upIcon} alt="downIcon"/>}
               </IconButton>
               <IconButton disableRipple className={"btn "+(status ? 'disable_ele' : '')} onClick={()=>{ 
                  if(status){
                     return;
                  }
                  onClose(open ,setOpen)
               }}>
                  <img src="/close.svg" alt="closeIcon" />
               </IconButton>
            </div>
         </div>
         <Collapse orientation="vertical" in={open} style={{ width: "100%" }}>
            <div className="queue-body-container">
               {queueItems?.map((item) => {
                  return <RenderListItem key={item.id}  item={item}/>
               })}
            </div>
         </Collapse>
      </div >
   );
};

export default ItemInQueueComponent;