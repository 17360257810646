import { useState, useEffect, useRef } from "react";
import PdfTemplateDetail from "./PdfTemplateDetail";
import { Button, Dialog, Tooltip, Box, Drawer, IconButton,List, ListItem, ListItemText, Paper, Checkbox, Typography} from "@mui/material";
import axios from "axios";
import { cloneDeep } from 'lodash';
import ZoomButtons from "components/ZoomButtons";
import WebViewer from "@pdftron/webviewer";
import { APRYSE_L_KEY } from "api";
import CloseIcon from "assets/images/close.svg";
import { useSelector } from "react-redux";
import FilterIcon from "assets/images/filter.svg";
import SelectAllIcon from "assets/images/selectAll.svg";
import { fetchUrl } from "Urls";
import { useDropzone } from "react-dropzone";
import ToastComponent from "components/ToastComponent";
import {ConfirmDialogCheckbox } from "components/DrawerComponent/DisciplineSettingDialog";
import UploadIcon from "assets/images/upload.svg";
import SearchIcon from "assets/images/search.svg";
//import xml2js from "xml2js";
import { getXMLJSFromMakrupXML,exportAnnotationsCustomGlobal } from "components/SetsView/ShowPdf";
export const getWrapperFromAnnotaitionXMLJSON = (ffff) => {
  let retObj = { formFields: []};
  try{
    if (ffff?.xfdf?.annots?.length > 0) {
      ffff.xfdf.annots.map((markUpItem) =>
        markUpItem != null && Object.entries(markUpItem).map(([key, value]) =>
          value && value.map((anno_ac) =>
            anno_ac["trn-custom-data"] && anno_ac["trn-custom-data"].map((cus_data) => {
              var annotation;
              const cusData = JSON.parse(cus_data.$.bytes);
              const fieldDetail = {fieldType : cusData['trn-form-field-type']};
              fieldDetail.fieldName = cusData['trn-form-field-name'] ;
              fieldDetail.showIndicator = cusData['trn-form-field-show-indicator'] === 'true' ? true :
                                          cusData['trn-form-field-show-indicator'] === 'false' ? false : undefined ;
              fieldDetail.indicatorText = cusData['trn-form-field-indicator-text'] === "" || !cusData['trn-form-field-indicator-text'] ? undefined 
                                          : cusData['trn-form-field-indicator-text'];
              annotation = {
                Id: anno_ac.$.name, Subject: anno_ac.$.subject,
                name:fieldDetail.fieldName,
                markupType: anno_ac.$.subject,
                fieldType:fieldDetail.fieldType,
                markupDetail: anno_ac.$,
                fieldDetail: fieldDetail,
                customData: cusData
              };
              retObj.formFields.push(annotation);
              return annotation;
            })
          )
        )
      );
    }
  }catch (e){
    console.log('e :: ',e);
  }
  //console.log('retObj :: '+JSON.stringify(retObj));
  return retObj;
};
const BuildPdfTemplate = (props) => {
  const { templateId, onCancel, credential, _templateOptions, splitButtonAction, onAccept } = props;
  const [projectTemplateId, setProjectTemplateId] = useState();
  const [openFormDetails, setFormDetail] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [sfObj, setsfObjState] = useState(defaultObj());
  const sfObjRef = useRef(sfObj);
  const { userData = {} } = useSelector((state) => state.userData);
  const [showSpinner, setSpinner] = useState(false);
  const [hidePdfContainer, setHidePdfContainer] = useState(false);
  const [searchInPdf, setSearchInPdf] = useState(false);
  const [publishButtonDisabled, setPublishButtonDisabled] = useState(false);
  const [templatePublished, setTemplatePublishedState] = useState(false);
  const templatePublishedRef = useRef(templatePublished);
  const setTemplatePublished=(value)=>{
    templatePublishedRef.current = value;
    setTemplatePublishedState(value);
  }
  const [pagesPanel, setPagesPanelState] = useState(false);
  const [comboToolActive, setComboToolButton] = useState(false);
  const [openDetailsPanel, setOpenDetailsPanel] = useState(false);
  const [openSettingsPanel, setOpenSettingsPanel] = useState(false);
  const [openFieldsPanel, setOpenFieldsPanel] = useState(false);
  const [isFieldFiltred, setFieldFiltered] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [templateUpdated, setTemplateUpdatedState] = useState(false);
  const templateUpdatedRef = useRef(templateUpdated);

  const [toReplaceDocument, setToReplaceUpload] = useState([]);
  const [openReplacePdf, setOpenReplacePdf] = useState(false);
  const [openConfirmReplace, setOpenConfirmReplace] = useState(false);
  const [skipReplacePdfDialog, setSkipReplacePdfDialog] = useState(false);

  const [isFieldSelectionActive, setFieldSelectionActive] = useState(false);
  const existingFileName = useRef(null);
  const saveFile = useRef(null);
  let timeOutMilsec = useRef(1000);
  const viewer = useRef();
  const pagePanelRef = useRef(false);
  // webviewer variables
  const [instance, setInstanceState] = useState(null);
  const instanceRef = useRef(instance);
  const [currentToolMode, setCurrentToolMode] = useState();
  const [selectedMarkup, setSelectedMarkup] = useState();
  const toolModeRef = useRef(currentToolMode);
  const [showZoomActions, setShowZoomAction] = useState(false);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
  const [formFieldDetails, setFormFieldDetailState] = useState(null);
  const formFieldDetailRef = useRef(formFieldDetails);
  const addPageRef = useRef();
  const { getRootProps, getInputProps, inputRef } = useDropzone({ onDrop, multiple: false, accept: { 'application/pdf': ['.pdf'] } });
  // webviewer variables
  const confirmReplacePdfDialog = {
    title: 'Replace PDF?',
    content: 'Are you sure you want to replace this PDF? Once replaced, all created fields will be deleted.',
    action1: "Cancel",
    action2: "Yes, Replace PDF"
  };
  useEffect(() => {
    //console.log('files use effect ::',userData?.projectId,_projectId);
    if (templateId) {
      getTemplateDetailsFromServer(templateId);
    } else {
      setFormDetail(true);
    }
  }, []);
  useEffect(() => {
    if (userData?.pdfSkipReplacePdfDialog) {
      setSkipReplacePdfDialog(true);
    } else {
      setSkipReplacePdfDialog(false);
    }
  }, [ userData?.pdfSkipReplacePdfDialog ]);
  const setInstance = (state)=>{
    instanceRef.current = state;
    setInstanceState(state);
  };
  const setFormFieldDetails = (value) => {
    formFieldDetailRef.current = value;
    setFormFieldDetailState(value);
  };
  const setCurrentToolMode_ = (value) => {
    toolModeRef.current = value;
    setCurrentToolMode(value);
  };
  function defaultObj() {
    return { Name: '', Template_Category__c: 'Daily Report', Form_Type__c: 'PDF' };
  }
  const setsfObj = (state)=>{
    sfObjRef.current = state;
    setsfObjState(state);
  };
  function setVariables(_sfObj) {
    setsfObj(_sfObj);
  }
  const handleOnClose = ()=>{
    onCancel(templateUpdated);
  };
  function onDrop(acceptedFiles) {
    let _dropFiles = [];
    if (acceptedFiles.length > 0) {
      for (let doc of acceptedFiles) {
        _dropFiles.push({ attachId: null, fileName: doc.name, file: doc });
      }
    }
    setToReplaceUpload(_dropFiles);
  };
  const getTemplateDetailsFromServer = async (_templateId) => {
    setSpinner(true);
    setHidePdfContainer(false);
    await axios.get(`${fetchUrl}/getFormTemplate/${_templateId}?projectId=${credential?.projectId}`)
      .then((res) => {
        //console.log("count:::: " + JSON.stringify(res));
        if (res.status === 200) {
          //{formObj : cObj , docDetail : docReturn}
          if (res.data?.formObj) {
            setVariables(res.data.formObj);
            setTemplatePublished(res.data.formObj.Status__c === 'Published');
            if(res.data.formObj.Project_Form_Templates__r?.length > 0){
              setProjectTemplateId(res.data.formObj.Project_Form_Templates__r[0].Id);
            }
            if (res.data.docDetail) {
              saveFile.current = false;
              //setSaveFile(false);
              existingFileName.current = res.data.docDetail.File_Name__c;
              setTimeout(() => {
                initLoadPDF(res.data.docDetail.end_url);
              },100);
            }
            return;
          }
        }
      }).catch((err) => {
        console.log(err);
      });
    setSpinner(false);
  }
  async function selectAnnotationById(annId, _instance = instance) {
    const sel_ann = _instance.Core.annotationManager.getAnnotationsList().find(annotation => annotation.Id === annId);
    //console.log('select annotation :: ',sel_ann , annId);
    if (sel_ann) {
      //deselectAnnotation(_instance);
      _instance.Core.annotationManager.selectAnnotation(sel_ann);
    }
  }
  async function deselectAnnotationById(annId, _instance = instance) {
    const sel_ann = _instance.Core.annotationManager.getAnnotationsList().find(annotation => annotation.Id === annId);
    if (sel_ann) {
        // delete associated issue with it
      //  deselectAnnotation(_instance);
        _instance.Core.annotationManager.deselectAnnotation(sel_ann);
    }
  }
  async function deselectAnnotation(_instance = instance) {
    _setSelectedAnnotation(undefined);
    _instance.Core.annotationManager.deselectAllAnnotations();
  }
  async function deleteSelectedAnnotations(_instance = instanceRef.current) {
    //console.log('_instance :: ',_instance);
    const selecAnnotaions = _instance.Core.annotationManager.getSelectedAnnotations().filter(item => item.isFormFieldPlaceholder() );
    if(selecAnnotaions?.length > 0 ){
      await deleteAnnotationList(selecAnnotaions , _instance);
      setSelectedMarkup([]);
    }
  }
  async function deleteAnnotationList(annoList, _instance = instance) {
    await _instance.Core.annotationManager.deleteAnnotations(annoList);
  }
  function _setSelectedAnnotation(ann) {
    setSelectedMarkup(ann);
  }
  async function initLoadPDF(fileURI) {
    if (viewer.current) {
      setShowZoomAction(false);
      const disElement = [
        "leftPanelTabs",
        "zoomThumbOutButton",
        "thumbnailsSizeSlider",
        "toolbarGroup-View",
        "toolsHeader",
        "toolbarGroup-Annotate",
        "toolbarGroup-Shapes",
        "toolbarGroup-Insert",
        "toolbarGroup-Measure",
        "toolbarGroup-Edit",
        "toolbarGroup-FillAndSign",
        "toolbarGroup-Forms",
        "toolbarGroup-Redact",
        "annotationGroupButton",
        "linkButton",
        "annotationCommentButton",
        "thumbnailControl",
        "documentControl",
        "toolStylePopup",
        "leftPanelResizeBar",
        "contextMenuPopup",
        "replacePage",
        "insertPage",
        "extractPage",
        "searchPanelResizeBar",
      ];
      if(templatePublishedRef.current){
        disElement.push('pageManipulationOverlay');
        disElement.push('formFieldEditPopup');
      }
      /* 
      "annotationDeleteButton",
      "annotationStyleEditButton"*/
      await WebViewer({ fullAPI: true, path: "/webviewer/public",
        initialDoc: fileURI,
        disabledElements: disElement,
        licenseKey: APRYSE_L_KEY }, viewer.current )
      .then(async (instance) => {
        setInstance(instance);
        setShowZoomAction(true);
        const { documentViewer, annotationManager, Tools, PDFNet} = instance.Core;
        await PDFNet.initialize();
        const formCreationManager = annotationManager.getFormFieldCreationManager();
        if(!templatePublishedRef.current){
          formCreationManager.startFormFieldCreationMode();
        }
        const iframeWindow = instance.UI.iframeWindow;
        const header = iframeWindow.document.querySelector('[data-element="header"]');
        header.style.display = 'none';
        const defaultMarkupTool = documentViewer.getTool(Tools.ToolNames.EDIT);
        //defaultMarkupTool.disableImmediateActionOnAnnotationSelection();
        const textFieldTool = documentViewer.getTool(Tools.ToolNames.TEXT_FORM_FIELD);
        //console.log('textFieldTool ',textFieldTool.name);
        const textFieldTool2 = documentViewer.getTool(Tools.ToolNames.TEXT_FORM_FIELD2);
        textFieldTool2.setStyles((currentStyle) => (textFieldTool2.defaults));
        //console.log('textFieldTool2 ',textFieldTool2.name);
        const checkboxFieldTool = documentViewer.getTool(Tools.ToolNames.CHECK_BOX_FIELD);
        checkboxFieldTool.setStyles((currentStyle) => (checkboxFieldTool.defaults));
        //console.log('checkboxFieldTool ',checkboxFieldTool.name);
        const radioFieldTool = documentViewer.getTool(Tools.ToolNames.RADIO_FORM_FIELD);
        radioFieldTool.setStyles((currentStyle) => (radioFieldTool.defaults));
        //console.log('radioFieldTool ',radioFieldTool.name);
        const comboboxFieldTool = documentViewer.getTool(Tools.ToolNames.COMBO_BOX_FIELD);
        comboboxFieldTool.setStyles((currentStyle) => (comboboxFieldTool.defaults));
        //console.log('comboboxFieldTool ',comboboxFieldTool.name);
        const listboxFieldTool = documentViewer.getTool(Tools.ToolNames.LIST_BOX_FIELD);
        listboxFieldTool.setStyles((currentStyle) => (listboxFieldTool.defaults));
        //console.log('listboxFieldTool ',listboxFieldTool.name);
        const signatureFieldTool = documentViewer.getTool(Tools.ToolNames.SIG_FORM_FIELD);
        signatureFieldTool.setStyles((currentStyle) => (signatureFieldTool.defaults));
        //console.log('signatureFieldTool ',signatureFieldTool.name);
        documentViewer.addEventListener("pagesUpdated", (changes) => {
          console.log('doc pages updated');
          setTimeout(()=>{
            const docu = documentViewer.getDocument();
            const pageCount = docu.getPageCount();
            //console.log('in timeout',pageCount);
            const leftPanelButton = iframeWindow.document.querySelector('[data-element="leftPanelButton"]');
            const leftPanel = iframeWindow.document.querySelector('[data-element="leftPanel"]');
            if(leftPanelButton && leftPanel){
              //console.log('in condition');
              const thumPaneldiv = iframeWindow.document.querySelector('.thumb-panel-title-container-text');
              if(thumPaneldiv){
                //console.log('in found');
                thumPaneldiv.textContent = `${pageCount} Pages`;
              }
            }
          },500);
        });
        documentViewer.addEventListener("toolModeUpdated", (newToolObject , oldToolObject) => {
          setCurrentToolMode_(newToolObject.name);
        });
        formCreationManager.addEventListener('formFieldCreationModeStarted', () => {
          console.log('form creation start');
        });
    
        formCreationManager.addEventListener('formFieldCreationModeEnded', () => {
          console.log('form creation end');
        });
        documentViewer.addEventListener("documentLoaded", async () => { 
          //console.log('tool documentLoaded mode first' ,documentViewer.getToolMode().name);
          documentViewer.setToolMode(defaultMarkupTool);
          setCurrentToolMode_(defaultMarkupTool.name);
          //console.log('tool documentLoaded mode' ,documentViewer.getToolMode().name);
          setCurrentZoomLevel(documentViewer.getZoomLevel());
          const docu = documentViewer.getDocument();
          const pageCount = docu.getPageCount();
          const leftPanelButton = iframeWindow.document.querySelector('[data-element="leftPanelButton"]');
          const leftPanel = iframeWindow.document.querySelector('[data-element="leftPanel"]');
          const thumbnailPanel = document.getElementById('thubmnail-panel');
          if (thumbnailPanel && leftPanel) {
            thumbnailPanel.addEventListener('click', () => {
              if(pagePanelRef.current){
                const pdfContainer = leftPanel.querySelectorAll('.container');
                pdfContainer.forEach(element => {
                  element.style.height = "100px";
                });
              }
            })
          }
          styleLibraryLeftPanel(leftPanel , pageCount , leftPanelButton);
          async function styleLibraryLeftPanel(leftPanel , pageCount , leftPanelButton){
            const leftPanelContainer = leftPanel.querySelector('.left-panel-container');
            leftPanelContainer.style.padding = "0px";
            const leftPanelHeader = leftPanel.querySelector('.left-panel-header');
            leftPanelHeader.style.backgroundColor = "white";
            leftPanelHeader.style.margin = "0px";
            leftPanelHeader.style.padding = "0px";
            leftPanelHeader.style.borderBottom = "1px solid #EAEAEA";
            const thumPanelTitle = iframeWindow.document.createElement('div');
            thumPanelTitle.classList.add('thumb-panel-title');
            thumPanelTitle.style.width = "100%";
            const pdfContainer = iframeWindow.document.querySelectorAll('.container');
            pdfContainer.forEach(element => {element.style.height = "100px";});
            const thumPanelTitleContainer = iframeWindow.document.createElement('div');
            thumPanelTitleContainer.classList.add('thumb-panel-title-container');
            thumPanelTitleContainer.style.display = "flex";
            thumPanelTitleContainer.style.gap = "90px";
            thumPanelTitleContainer.style.padding = "12px 16px";
            thumPanelTitleContainer.style.borderBottom = "1px solid #EAEAEA";
            thumPanelTitleContainer.style.width = "100%";
            const titContainerInnerDiv = iframeWindow.document.createElement('div');
            titContainerInnerDiv.classList.add('thumb-panel-title-container-text');
            titContainerInnerDiv.style.display = "flex";
            titContainerInnerDiv.style.gap = "90px";
            titContainerInnerDiv.style.padding = "12px 16px";
            titContainerInnerDiv.style.width = "100%";
            titContainerInnerDiv.textContent =  `${pageCount} Pages`;
            titContainerInnerDiv.color = '#505050';
            const leftIconContainer = iframeWindow.document.createElement('div');
            const closeIconImg = iframeWindow.document.createElement('img');
            closeIconImg.alt = 'Image Description';
            closeIconImg.src = '/close.svg'; 
            closeIconImg.style.cursor = "pointer";
            closeIconImg.addEventListener('mouseover', function() {
              closeIconImg.style.backgroundColor = '#F8F8F8'; 
            });
            closeIconImg.addEventListener('mouseout', function() {
              closeIconImg.style.backgroundColor = 'initial'; // Reset to the default background color
            });
            closeIconImg.addEventListener('click', function() {setPagesPanel(false);
              leftPanelButton.click();
            });
            thumPanelTitleContainer.textContent = 'Pages';
            thumPanelTitleContainer.style.fontWeight = 600;
            thumPanelTitleContainer.style.fontSize = "14px";
            thumPanelTitleContainer.style.color = "black";
            leftPanelHeader.appendChild(thumPanelTitle);
            thumPanelTitle.appendChild(thumPanelTitleContainer);
            thumPanelTitle.appendChild(titContainerInnerDiv);
            thumPanelTitleContainer.appendChild(leftIconContainer);
            thumPanelTitleContainer.appendChild(closeIconImg);
            leftPanel.style.backgroundColor = '#F8F8F8';
            leftPanel.style.height = '100vh';
            leftPanel.style.borderLeft = '1px solid #D8D8D8';
            leftPanel.style.boxShadow = '2px 0px 3px #ccc';
          }
          if(true){
            const zOutEle = document.getElementById('zoom-out-button');
            if(zOutEle){
              if(!zOutEle.dataset?.clickBound || zOutEle.dataset.clickBound != 'yes'){
                zOutEle.dataset.clickBound = 'yes';
                zOutEle.addEventListener('click', () => {
                  let preLevel = documentViewer.getZoomLevel();
                  if(preLevel > 0.1){
                    documentViewer.zoomTo(documentViewer.getZoomLevel() - 0.25);
                  }else{
                    documentViewer.zoomTo(0.1);
                  }
                });
              }
            }
            const zInEle = document.getElementById('zoom-in-button');
            if(zInEle){
              if(!zInEle.dataset?.clickBound || zInEle.dataset.clickBound != 'yes'){
                zInEle.dataset.clickBound = 'yes';
                zInEle.addEventListener('click', () => {
                  documentViewer.zoomTo(documentViewer.getZoomLevel() + 0.25);
                });
              }
            }
            const multiSelectTool = documentViewer.getTool(Tools.ToolNames.EDIT);
            const comboToolBtn = document.getElementById("combo-tool-button");
            if (comboToolBtn) {
              comboToolBtn.addEventListener("click", () => {
                setTimeout(() => {
                  console.log("This will run after 1 second!");
                  const panAnnotation = documentViewer.getTool(Tools.ToolNames.PAN);
                  const panToolBtn = document.getElementById("pan-tool-button");
                  if (panToolBtn) {
                    panToolBtn.addEventListener("click", () => {
                      return;
                      if(panAnnotation.name !== documentViewer.getToolMode().name){
                        documentViewer.setToolMode(panAnnotation);
                      }else{
                        documentViewer.setToolMode(defaultMarkupTool);
                      }
                      setCurrentToolMode_(documentViewer.getToolMode().name);
                    });
                  }
                  const editToolBtn = document.getElementById("multi-tool-button");
                  if (editToolBtn) {
                    editToolBtn.addEventListener("click", () => {
                      if(multiSelectTool.name !== documentViewer.getToolMode().name){
                        documentViewer.setToolMode(multiSelectTool);
                      }else{
                        documentViewer.setToolMode(defaultMarkupTool);
                      }
                      setCurrentToolMode_(documentViewer.getToolMode().name);
                    });
                  }
                }, 100);
              });
            }
            const textFieldBtn = document.getElementById("text_field_button");
            //console.log('textFieldBtn ',textFieldBtn);
            if (textFieldBtn) {
              textFieldBtn.addEventListener("click", () => {
                //console.log('in click ');
                if(textFieldTool.name !== documentViewer.getToolMode().name){
                  documentViewer.setToolMode(textFieldTool);
                }else{
                  documentViewer.setToolMode(defaultMarkupTool);
                }
                setCurrentToolMode_(documentViewer.getToolMode().name);
              });
            }
            const dateFieldBtn = document.getElementById("date_field_button");
            if (dateFieldBtn) {
              dateFieldBtn.addEventListener("click", async () => {
                /*const annots = await instance.Core.annotationManager.getAnnotationsList();
                await instance.Core.annotationManager.deleteAnnotations(annots);*/
                //formCreationManager.applyFormFields();
                let xfdfString  =await instance?.Core.annotationManager.exportAnnotations().then(async function (xfdfString) {
                 console.log("actual String ::", xfdfString);
                 return xfdfString;
               });
               return;
                if(textFieldTool2.name !== documentViewer.getToolMode().name){
                  documentViewer.setToolMode(textFieldTool2);
                }else{
                  documentViewer.setToolMode(defaultMarkupTool);
                }
                setCurrentToolMode_(documentViewer.getToolMode().name);
              });
            }
            const checkboxFieldBtn = document.getElementById("checkbox_field_button");
            if (checkboxFieldBtn) {
              checkboxFieldBtn.addEventListener("click", () => {
                if(checkboxFieldTool.name !== documentViewer.getToolMode().name){
                  documentViewer.setToolMode(checkboxFieldTool);
                }else{
                  documentViewer.setToolMode(defaultMarkupTool);
                }
                setCurrentToolMode_(documentViewer.getToolMode().name);
              });
            }
            const radioFieldBtn = document.getElementById("radio_field_button");
            if (radioFieldBtn) {
              radioFieldBtn.addEventListener("click", () => {
                if(radioFieldTool.name !== documentViewer.getToolMode().name){
                  documentViewer.setToolMode(radioFieldTool);
                }else{
                  documentViewer.setToolMode(defaultMarkupTool);
                }
                setCurrentToolMode_(documentViewer.getToolMode().name);
              });
            }
            const comboboxFieldBtn = document.getElementById("combobox_field_button");
            if (comboboxFieldBtn) {
              comboboxFieldBtn.addEventListener("click", () => {
                if(comboboxFieldTool.name !== documentViewer.getToolMode().name){
                  documentViewer.setToolMode(comboboxFieldTool);
                }else{
                  documentViewer.setToolMode(defaultMarkupTool);
                }
                setCurrentToolMode_(documentViewer.getToolMode().name);
              });
            }
            const listboxFieldBtn = document.getElementById("listbox_field_button");
            if (listboxFieldBtn) {
              listboxFieldBtn.addEventListener("click", () => {
                if(listboxFieldTool.name !== documentViewer.getToolMode().name){
                  documentViewer.setToolMode(listboxFieldTool);
                }else{
                  documentViewer.setToolMode(defaultMarkupTool);
                }
                setCurrentToolMode_(documentViewer.getToolMode().name);
              });
            }
            const signatureFieldBtn = document.getElementById("signature_field_button");
            if (signatureFieldBtn) {
              signatureFieldBtn.addEventListener("click", () => {
                if(signatureFieldTool.name !== documentViewer.getToolMode().name){
                  documentViewer.setToolMode(signatureFieldTool);
                }else{
                  documentViewer.setToolMode(defaultMarkupTool);
                }
                setCurrentToolMode_(documentViewer.getToolMode().name);
              });
            }
          }
          /*if (xmlTojsonXML?.current) {
            //console.log('in import');
            await annotationManager.importAnnotations(xmlTojsonXML.current);
          }*/
          annotationManager.addEventListener("annotationChanged", async (annotations, action, { imported , source}) => {
            let fsf = formCreationManager.isInFormFieldCreationMode();
            console.log('annotationChanged action ::',fsf, action, annotations?.length, imported , source);
            if(!fsf){ // || imported !== undefined
              return;
            }
            if(!imported){
              if(action === 'add'){
                const ann = annotations[0];
                selectAnnotationById(ann.Id, instance);
              }
              if(action !== 'delete'){
                createCustomPopups();
              }else{
                console.log('deleted annotations :: ',annotations);
              }
            }else{
              /*if(action === 'add'){
                selectAnnotationById(ann.Id , instance);
              }*/
            }
            setTimeout(async ()=>{
              /*if(imported === true){
                // when markups are imported we dont need to select that markup
                deselectAnnotation(instance);
              }*/
              let xfdfString =await annotationManager.exportAnnotations();
              //console.log("xfdfString ::", xfdfString);
              let annJs = await getXMLJSFromMakrupXML(xfdfString);
              //console.log("annJs ::", JSON.stringify(annJs));
              let detailObj = getWrapperFromAnnotaitionXMLJSON(annJs);
              //console.log("detailObj ::", JSON.stringify(detailObj));
              setFormFieldDetails(detailObj);
            },10);
          });
          annotationManager.addEventListener("annotationSelected", async (annotations, action) => {
            console.log('annotationSelected action :: ', action);
            //formCreationManager.startFormFieldCreationMode();
            const selecAnnotaions = annotationManager.getSelectedAnnotations();
            var markups = selecAnnotaions.filter((item) => item.isFormFieldPlaceholder());
            if (action === "selected") {
              if(annotations?.length > 0 ){
                var customData = annotations[0];
                //console.log("customData ::", customData);
                const isMultiselect = annotations?.length > 1;
                if (customData) {
                  createCustomPopups();
                }
              }
            } else {
              //setSelectedMarkup(undefined);
            }
            setSelectedMarkup(markups);
          });
          async function createCustomPopups(){            
            if(templatePublishedRef.current){
              return;
            }
            setTimeout(async () => {
              const docContainer = await getContainerElement();
              if (docContainer) {
                const styleButton = iframeWindow.document.querySelector('[data-element="annotationStyleEditButton"]');
                const deleteButton = iframeWindow.document.querySelector('[data-element="annotationDeleteButton"]');
                const formEditeButton = iframeWindow.document.querySelector('[data-element="formFieldEditButton"]');
                if (styleButton) {
                  styleButton.style.display = 'none';
                }
                if (formEditeButton) {
                  formEditeButton.style.display = 'none';
                }
                if (deleteButton) {
                  deleteButton.style.display = 'none';
                }
                //console.log("run",currentIds);
                const container = docContainer.querySelector(".container");
                if(container){
                  container.style.background = "white";
                  //container.style.width = "292px";
                  container.style.height = "70px";
                  const child_container = container.querySelector(".child-flex-container");
                  if (child_container) {
                    container.removeChild(child_container);
                    //console.log('removed :: child-flex-container');
                  }
                  // Create a container div for flex layout
                  const flexContainer = document.createElement("div");
                  flexContainer.classList.add("child-flex-container");
                  //console.log('created :: child-flex-container');
                  flexContainer.style.display = "flex";
                  flexContainer.style.padding = "2px";
  
                  // Define an array of content for the divs with different class names
                  const divContent = [];
                      divContent.push({
                        imageUrl: "/Pencil.svg",
                        altText: "Pencil Icon",
                        text: "Edit",
                        className: "div-class-1", // Add a unique class name
                        clickHandler: (event) => {
                          const editStyle = iframeWindow.document.querySelector('[data-element="formFieldEditButton"]');
                          if (editStyle) {
                            editStyle.click();
                           // createCustomPopupsEditForm();
                          }
                        },
                      });
                    divContent.push({
                      imageUrl: "/delete.svg",
                      altText: "delete Icon",
                      text: "Delete",
                      className: "div-class-3", // Add a unique class name
                      clickHandler: (event) => {
                        const deleteButton = iframeWindow.document.querySelector('[data-element="annotationDeleteButton"]');
                        if (deleteButton) {
                          deleteButton.click();
                        }
                      },
                    });
                  if(divContent?.length){
                    // Loop through the content array and create div elements
                    divContent.forEach((content, index) => {
                      // Create a new div element
                      const newDiv = document.createElement("div");
                      if(content.divId){
                        newDiv.id = content.divId;
                      }
                      newDiv.style.color = "#505050";
                      newDiv.style.paddingLeft = content.className == "div-class-2" ? "13px" : content.className == "div-class-3" ? "30px" : "35px";
                      newDiv.style.paddingTop = "8px";
                      newDiv.style.width = "94px";
                      newDiv.style.cursor = "pointer";
                      // Set the class name based on the className property
                      newDiv.className = content.className;
                      newDiv.classList.add("div-hover");
                      newDiv.addEventListener("click", content.clickHandler);
                      newDiv.addEventListener("mouseover", mouseOver, false);
                      newDiv.addEventListener("mouseout", mouseOut, false);
                      function mouseOver() {
                        newDiv.style.backgroundColor = "rgba(25, 118, 210, 0.04)";
                      }
                      function mouseOut() {
                        newDiv.style.backgroundColor = "white";
                      }
                      // Create an img element
                      const imgElement = document.createElement("img");
                      imgElement.src = content.imageUrl;
                      imgElement.alt = content.altText;
                      imgElement.style.paddingLeft = content.className == "div-class-2" ? "25px" : content.className == "div-class-3" ? "10px" : "5px";
                      // Create a paragraph element for text
                      const textElement = document.createElement("p");
                      textElement.textContent = content.text;
                      // Append the image and text elements to the new div
                      newDiv.appendChild(imgElement);
                      newDiv.appendChild(textElement);
                      // Append the new div to the flex container
                      flexContainer.appendChild(newDiv);
                    });
    
                    // Append the flex container to the parent div
                    container.appendChild(flexContainer);
                  }
                  timeOutMilsec.current = 100;
                }
              }
              async function getContainerElement(){
                var docCon = iframeWindow.document.querySelector('[data-element="annotationPopup"]');
                var count = 0;
                while (!docCon && count < 200) {
                  docCon = iframeWindow.document.querySelector('[data-element="annotationPopup"]');
                  if (docCon) {
                    break;
                  }
                  await delay(300);
                  count++;
                }
                return docCon;
              }
              async function delay(ms) {
                return new Promise(resolve => setTimeout(()=>{
                  //console.log('delay '+count);
                  resolve();
                }, ms));
              }
            }, timeOutMilsec.current);
          }
          async function createCustomPopupsEditForm(){
            setTimeout(async () => {
            const editPopup = iframeWindow.document.querySelector('[data-element="formFieldEditPopup"]');
            if(editPopup){
              const editPopupInner = iframeWindow.document.querySelector('.FormFieldEditPopup');
              if(editPopupInner){
                const fieldsContainer = iframeWindow.document.querySelector('.fields-container');
                if(fieldsContainer){
                  const fieldsInput = iframeWindow.document.querySelector('.field-input');
                  if(fieldsInput){
                    fieldsInput.style.display= "block";
                  }
                }
              }
            }
          }, timeOutMilsec.current);
          }
        });
      }).catch((err)=>{
        console.log('err' ,err);
      });
    }
  }
  const handleToggleDrawerPanel = (section) => {
    //console.log('section , controlled  :: ', section, controlled);
    //console.log('handleToggleDrawerPanel instance ', instance);
    let leftPanelButton ;
    if (instanceRef.current) { 
      leftPanelButton = instanceRef.current.UI.iframeWindow.document.querySelector('[data-element="leftPanelButton"]');
    }
     if (section === "pages") {
      if (leftPanelButton) {
        leftPanelButton.click();
        setPagesPanel(!pagePanelRef.current);
      }
      setOpenDetailsPanel(false);
      setOpenFieldsPanel(false);
      setOpenSettingsPanel(false);
    }else  if (section === "details") {
      setOpenDetailsPanel(!openDetailsPanel);
      setOpenSettingsPanel(false);
      setOpenFieldsPanel(false);
      if (pagePanelRef.current && leftPanelButton) {
        leftPanelButton.click();
        setPagesPanel(false);
      }
    }else  if (section === "settings") {
      setOpenSettingsPanel(!openSettingsPanel);
      setOpenFieldsPanel(false);
      setOpenDetailsPanel(false);
      if (pagePanelRef.current && leftPanelButton) {
        leftPanelButton.click();
        setPagesPanel(false);
      }
    }else  if (section === "fields") {
      setOpenFieldsPanel(!openFieldsPanel)
      setOpenSettingsPanel(false);
      setOpenDetailsPanel(false);
      if (pagePanelRef.current && leftPanelButton) {
        leftPanelButton.click();
        setPagesPanel(false);
      }
    }
    else  if (section === "closeAll") {
      setOpenFieldsPanel(false)
      setOpenSettingsPanel(false);
      setOpenDetailsPanel(false);
      if (pagePanelRef.current && leftPanelButton) {
        leftPanelButton.click();
        setPagesPanel(false);
      }
    }
  };
  const setPagesPanel= (value) => {
    pagePanelRef.current = value;
    setPagesPanelState(value);
  };
  const onPublishTemplate = async () => {
    let fff = sfObj;
    fff.Status__c = 'Published';
    setVariables(fff);
    // need to put status as published for the template and then save
    let tmsg = `Template Details has been updated.`;
    let val = await onClickSave(tmsg);
    if(val==true){
      setTimeout(()=>{handleOnClose()},2000);
    }
  };
  const onSaveAsDraft = async (closeOnSave)=>{
    let tmsg = `Template Details has been updated.`;
    let val = await onClickSave( tmsg);
    if(val === true ){
      if(closeOnSave === true){
        handleOnClose();
      }else{
        //getTemplateDetailsFromServer();
      }
    }
  };
  const setTemplateUpdated =(value)=>{
    templateUpdatedRef.current = value;
    setTemplateUpdatedState(value);
  }
  const onClickSave = async ( message )=>{
    const rec = sfObjRef.current;
    const { documentViewer, annotationManager } = instanceRef.current.Core;
    const formCreationManager = annotationManager.getFormFieldCreationManager();
    if(sfObj?.Status__c === 'Published'){
      formCreationManager.applyFormFields();
    }
    const doc = documentViewer.getDocument();
    const xfdfString = await annotationManager.exportAnnotations();
    console.log('save xfdfString ::',xfdfString);
    const options = { xfdfString };
    const data = await doc.getFileData(options);
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: 'application/pdf' });
    const image = URL.createObjectURL(blob);
    console.log('image :: ',image);
    const formData = new FormData();
    formData.append("templateFile", blob, existingFileName.current);
    formData.append("fileTitle", existingFileName.current);
    formData.append("putOnAmazon", true);
    formData.append("formRec", JSON.stringify(rec));
    return await axios.post(`${fetchUrl}/saveFormTemplate/Form_Template__c`, formData)
      .then((res) => {
        rec.Id = res.data.id;
        setVariables(rec);
        setToastMessage(message);
        setTemplatePublished(sfObj?.Status__c === 'Published');
        if(sfObj?.Status__c === 'Published'){
          setTemplateUpdated(true);
          if(!templatePublishedRef.current){
            instanceRef.current.UI.disableElements(['pageManipulationOverlay','formFieldEditPopup']);
          }
          if(projectTemplateId){
            const fData = new FormData();
            let recTosave = {Id: projectTemplateId, Status__c: 'Active'};
            fData.append("recObj", JSON.stringify(recTosave));
            axios.post(`${fetchUrl}/recSave/Project_Form_Template__c`, fData)
          }
        }
        if (!rec.Id) {
          setTemplateUpdated(true);
          const formData2 = new FormData();
          //let issueObj = sfIssueObj;
          let rec2 = { Form_Template__c: rec.Id, Project__c: credential?.projectId };
          formData2.append("recObj", JSON.stringify(rec2));
          axios.post(`${fetchUrl}/recSave/Project_Form_Template__c`, formData2);
        }
        return true;
      }).catch((err) => {
        console.log(err);
        setSpinner(false);
        return false;
     });
  };
  const onCloseReplacePdf= (value) => {
    setToReplaceUpload([]);
    setOpenReplacePdf(false);
  };
  const onAcceptReplacePdf= (value) => {
    if(skipReplacePdfDialog){

    }else{
      setOpenConfirmReplace(true);
    }
    setOpenReplacePdf(false);
  };
  const handleReplacePdf= async(value) => {
    const formManager = instance.Core.annotationManager.getFormFieldCreationManager();
    formManager.endFormFieldCreationMode();
    setSpinner(true);
    setOpenConfirmReplace(false);
    setOpenReplacePdf(false);
    let rec = {Id: sfObj?.Id, 
    Name: sfObj?.Name};
    setFormDetail(false);
    const formData = new FormData();
    formData.append("templateFile", toReplaceDocument[0].file, toReplaceDocument[0].fileName);
    formData.append("fileTitle", toReplaceDocument[0].fileName);
    formData.append("putOnAmazon", true);
    formData.append("formRec", JSON.stringify(rec));
    setHidePdfContainer(true);
    await axios.post(`${fetchUrl}/saveFormTemplate/Form_Template__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
      .then((res) => {
        instance.UI.closeDocument().then(async function () {
          getTemplateDetailsFromServer(sfObj?.Id);
          setToReplaceUpload([]);
        })
      })
  };
  const handleSelectField = (annId, isChecked) => {
    if (isChecked === true) {
      selectAnnotationById(annId, instance);
    } else {
      deselectAnnotationById(annId, instance);
    }
  };
  function handleSearchInPdf(ann, type) {
    let pdfSearchButton;
    pdfSearchButton = instance.UI.iframeWindow.document.querySelector('[data-element="searchButton"]');
    pdfSearchButton.click();
    setSearchInPdf(!searchInPdf);

  }
  const handleToggleSelection = (annId, isChecked) => {
    deselectAnnotation();
    setFieldSelectionActive(!isFieldSelectionActive);
  };
  const FieldItemDetail = ({ annId, options, primary, handleSelectAnn }) => {
    return (
      <>
        <ListItemText
          primary={<Typography style={{fontSize: "14px", fontWeight: 400}}>{primary}</Typography>}
        />
        {options?.fieldDetail?.showIndicator && options?.fieldDetail?.showIndicator === true &&
          <IconButton >
            <img src="indicatorFieldIcon.svg" alt="indicatorFieldIcon" />
          </IconButton>
        }
      </>
    );
  };
  const addPageDocument = async (options , _instance = instanceRef.current) => {
    if (options?.length > 0 && options?.length === 1) {
      const { documentViewer } = _instance.Core;
      const orgDoc = documentViewer.getDocument();
      const docToInsert = await _instance.Core.createDocument(URL.createObjectURL(options[0]), {});
      orgDoc.insertPages(docToInsert).then(() => {
        // page 4, 5, and 6 from 'another.pdf' has been inserted into to the current pdf
        //console.log('new doc :: '+orgDoc.getPageCount()); // 6
        handleToggleDrawerPanel('pages');
      });
    }
  };
  return (<>
    <Dialog open={(openFormDetails && !sfObj?.Id) ? false : true} fullScreen>
      <div className="builder">
        <div className="builder_head" >
          <div className="builder_head_left" onClick={()=>{ handleOnClose(); }}>
            <div className="builder_head_left-container">
              <div>
                <img src="/left.svg" alt="leftIcon" />
              </div>
              <div className="header_label">
                Go to Templates
              </div>
            </div>
          </div>
          <div className="builder_head_center">
            {showSpinner &&
              <div className="header_label_dark">Loading...</div>
            }
            {!showSpinner &&
              <>
                <div className="header_label_dark">{sfObj?.Name}</div>
                {!templatePublished &&
                  <div className="clickable custom_button"
                    onClick={() => {
                      setCurrentStep(2);
                      setFormDetail(true);
                    }}>
                    <img src="/Pencil.svg" alt="leftIcon" />
                  </div>
                }
              </>
            }
          </div>
          <div className="builder_head_right">
            {!templatePublished &&
              <>
              {showZoomActions &&
                <div className="define-sheet-number-area body-regular" onClick={handleSearchInPdf} style={{ backgroundColor: searchInPdf && "#E8EFFF", alignSelf: "center" }}>
                  <Tooltip title="Search" placement="bottom">
                    <img src={SearchIcon} alt="search_icon" />
                  </Tooltip>
                </div>
              }
                <div className="header_icon">
                  <img style={{ cursor: "pointer" }} src="/show.svg" alt="leftIco" />
                </div>
                <div>
                  <Button className="custom_button cancel_button" disabled={showSpinner} onClick={()=>{onSaveAsDraft(false);}}>Save as Draft</Button>
                </div>
                <div>
                  <Button className="custom_button apply_button" onClick={onPublishTemplate} disabled={showSpinner || publishButtonDisabled}>
                    Publish Template
                  </Button>
                </div>
              </>
            }
            {templatePublished &&
              <>
                <div>
                  <Button className="custom_button cancel_button">
                    Edit Checklist Template
                  </Button>
                </div>
                <div>
                  <Button className="custom_button apply_button" onClick={() => onAccept(sfObj)} disabled={showSpinner || publishButtonDisabled}>
                    Create Form
                  </Button>
                </div>
              </>
            }
          </div>
        </div>
          <div className="builder_body" style={{paddingTop: "0px", display: "block"}}>
            <Box sx={{ display: "flex" }}>
              <div className="siderbar_drawer_sheets">
                <Button id="thubmnail-panel-custom" className="markup_button_style" style = {{borderBottom : "1px solid #EAEAEA"}} onClick={() => handleToggleDrawerPanel("pages")} >
                  <img style={{width: !pagesPanel && "15px"}} src={pagesPanel ? "/pages.svg" : "/pages-grey.svg"} alt="markup_icon" />
                  <span style={{ color: !pagesPanel ? "rgba(80, 80, 80, 1)" : "rgba(52, 110, 245, 1)",}} >
                    Pages
                  </span>
                </Button>
                {!templatePublished && 
                  <>
                    <Button className="markup_button_style" onClick={() => handleToggleDrawerPanel("fields")}>
                      <img src={openFieldsPanel ? "/layers_blue.svg" : "/layers.svg"} alt="markup_icon" />
                      <span style={{ color: !openFieldsPanel ? "rgba(80, 80, 80, 1)" : "rgba(52, 110, 245, 1)",}} >
                        Fields
                      </span>
                    </Button>
                    <Button className="markup_button_style" onClick={() => handleToggleDrawerPanel("details")}>
                      <img src={openDetailsPanel ? "/info.svg" : "/info_grey.svg"} alt=""/>
                      <span style={{ color: !openDetailsPanel ? "rgba(80, 80, 80, 1)" : "rgba(52, 110, 245, 1)",}}>
                        Details
                      </span>
                    </Button>
                    <Button className="markup_button_style" onClick={() => handleToggleDrawerPanel("settings")}>
                      <img src={openSettingsPanel ? "/settings_blue.svg" : "/settings.svg"} alt="issue_icon" />
                      <span style={{ color: !openSettingsPanel ? "rgba(80, 80, 80, 1)" : "rgba(52, 110, 245, 1)",}}>
                        Settings
                      </span>
                    </Button>
                  </>
                }
                {(openDetailsPanel || openSettingsPanel || openFieldsPanel) &&
                  <Drawer className={(openDetailsPanel || openSettingsPanel || openFieldsPanel) ? "drawer_Open" : "drawer_closed"}
                    sx={{
                      '&.drawer_Open.MuiDrawer-docked.MuiDrawer-root .MuiDrawer-paper': {
                        marginTop: "58px",
                        width: "320px"
                      },
                      '&.drawer_Open.MuiDrawer-docked.MuiDrawer-root':{
                        width: "320px"
                      }
                    }}
                    anchor="left" open={true} variant="persistent" ModalProps={{ BackdropProps: { invisible: true, } }}>
                    <div>
                      <div className="markups_head">
                        <Box className="markup_title">{openDetailsPanel ? "Form Details" : openFieldsPanel ? "Form Fields" : "Settings"}</Box>
                        <IconButton sx={{ borderRadius: 0 }}
                          onClick={() => {
                            handleToggleDrawerPanel('closeAll');
                          }}
                        >
                          <img src={CloseIcon} alt="close_icon" />
                        </IconButton>
                      </div>
                      <div>
                        {(openDetailsPanel || openSettingsPanel) &&
                          <div style={{ color: "#00000", fontSize: "14px", fontWeight: 600, padding: "10px 22px" }}>{openDetailsPanel ? "Legend" : "PDF Settings"}</div>
                        }
                        {openFieldsPanel &&
                          <>
                            <div className="markup_list" >
                              <div className="markups_subhead">
                                {isFieldSelectionActive ? (
                                  <Box style={{ color: "#505050" }}>
                                    {selectedMarkup?.length} Selected
                                  </Box>
                                ) : (
                                  <Box style={{ color: "#505050" }}>
                                    {formFieldDetails?.formFields?.length} Items
                                  </Box>
                                )}
                                <div>
                                  <Tooltip title="Selection">
                                    <IconButton sx={{ background: isFieldSelectionActive && "#E8EFFF", borderRadius: 0 }} onClick={handleToggleSelection}>
                                      <img src={SelectAllIcon} alt="select_all_icon" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Filter">
                                    <IconButton sx={{ borderRadius: 0 }} >
                                      <img src={FilterIcon} alt="filter_icon" />
                                      {isFieldFiltred &&
                                        <div className='ellipse'></div>
                                      }
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                              <List style={{ height: '77.5vh' }}>
                                {formFieldDetails?.formFields?.length > 0 &&
                                  formFieldDetails?.formFields.map((markUpItem, index) => {
                                    const _ann0 = (_annId) => {
                                      let anno = selectedMarkup?.findIndex((item) => item.Id == markUpItem.Id);
                                      return anno > -1 ? true : false;
                                    }
                                    return <ListItem className={_ann0(markUpItem.Id) ? "checkbox_selected" : ''} key={markUpItem.id + ' ' + markUpItem.Id}>
                                      {isFieldSelectionActive && (
                                        <Checkbox sx={{ color: "#D8D8D8", "&.Mui-checked": { color: "#346EF5", }, }}
                                          style={{ padding: "0px", marginRight: "10px", }}
                                          checked={_ann0(markUpItem.Id)}
                                          value={markUpItem.Id}
                                          onChange={(e) =>
                                            handleSelectField(markUpItem.Id, e.target.checked)
                                          }
                                        />
                                      )}
                                      <img
                                        src={markUpItem.fieldType == "TextFormField" ? "/textfield_blue.svg"
                                        : markUpItem.fieldType == "CheckBoxFormField" ? "/checkbox_orange.svg"
                                          : markUpItem.fieldType == "RadioButtonFormField" ? "/radiobuttonFormField.svg"
                                            : markUpItem.fieldType == "ListBoxFormField" ? "/comboboxFormField.svg"
                                              : markUpItem.fieldType == "ComboBoxFormField" ? "/comboboxFormField.svg"
                                                : markUpItem.fieldType == "SignatureFormField" ? "/signatureFormField.svg"
                                                  : markUpItem.fieldType == "DateFormField" ? "/dateFormField.svg"
                                                    : ""}
                                        alt="markup_icon"
                                      />
                                      {/* <ListItemText primary={markUpItem.name} onClick={()=>selectAnnotationById(markUpItem.Id , instanceRef.current)}/>*/}
                                      <FieldItemDetail annId={markUpItem.Id}
                                        primary={markUpItem.name} options={markUpItem} />
                                    </ListItem>
                                  })
                                }
                              </List>
                            </div>
                            {isFieldSelectionActive && selectedMarkup?.length > 0 && (
                              <div style={{ display: "flex", justifyContent: "center", gap: "15px", borderTop: "1px solid #EAEAEA", paddingTop: "5px", opacity: selectedMarkup.length ? "" : 0.5, }} >
                                <div className="mouseHover">
                                  <Tooltip title="Delete" placement="bottom">
                                    <IconButton sx={{ borderRadius: 0 }} onClick={async ()=>{
                                        await deleteSelectedAnnotations();
                                        setTimeout(async ()=>{
                                          if(formFieldDetailRef.current && (!formFieldDetailRef.current.formFields?.length > 0)){
                                            setFieldSelectionActive(false);
                                          }
                                        },50);
                                      }}>
                                      <img src="delete.svg" alt="delete icon" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                            )}
                          </>
                        }
                        {openDetailsPanel &&
                          <>
                            <div style={{ display: "flex", padding: "10px 22px", justifyContent: "space-between" }}>
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div><img src="/Ellipse_Blue.svg" alt="" /></div>
                                <div style={{ color: "#505050" }}>Text Field</div>
                              </div>
                              <div style={{ color: "#505050" }}>
                                {formFieldDetails?.formFields?.length > 0 ? <>
                                  {(formFieldDetails?.formFields?.filter((itm) => itm.fieldType === "TextFormField")).length}
                                </>
                                  : 0}
                              </div>
                            </div>
                            <div style={{ display: "flex", padding: "10px 22px", justifyContent: "space-between" }}>
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div><img src="/Ellipse_purple.svg" alt="" /></div>
                                <div style={{ color: "#505050" }}>Date Field</div>
                              </div>
                              <div style={{ color: "#505050" }}>
                                {formFieldDetails?.formFields?.length > 0 ? <>
                                  {(formFieldDetails?.formFields?.filter((itm) => itm.fieldType === "DateFormField")).length}
                                </>
                                  : 0}
                              </div>
                            </div>
                            <div style={{ display: "flex", padding: "10px 22px", justifyContent: "space-between" }}>
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div><img src="/Ellipse_orange.svg" alt="" /></div>
                                <div style={{ color: "#505050" }}>Check Box</div>
                              </div>
                              <div style={{ color: "#505050" }}>
                                {formFieldDetails?.formFields?.length > 0 ? <>
                                  {(formFieldDetails?.formFields?.filter((itm) => itm.fieldType === "CheckBoxFormField")).length}
                                </>
                                  : 0}
                              </div>
                            </div>
                            <div style={{ display: "flex", padding: "10px 22px", justifyContent: "space-between" }}>
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div><img src="/Ellipse_green.svg" alt="" /></div>
                                <div style={{ color: "#505050" }}>Radio Button</div>
                              </div>
                              <div style={{ color: "#505050" }}>
                                {formFieldDetails?.formFields?.length > 0 ? <>
                                  {(formFieldDetails?.formFields?.filter((itm) => itm.fieldType === "RadioButtonFormField")).length}
                                </>
                                  : 0}
                              </div>
                            </div>
                            <div style={{ display: "flex", padding: "10px 22px", justifyContent: "space-between" }}>
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div><img src="/Ellipse_yellow.svg" alt="" /></div>
                                <div style={{ color: "#505050" }}>List Box</div>
                              </div>
                              <div style={{ color: "#505050" }}>
                                {formFieldDetails?.formFields?.length > 0 ? <>
                                  {(formFieldDetails?.formFields?.filter((itm) => itm.fieldType === "ListBoxFormField")).length}
                                </>
                                  : 0}
                              </div>
                            </div>
                            <div style={{ display: "flex", padding: "10px 22px", justifyContent: "space-between" }}>
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div><img src="/Ellipse_yellow.svg" alt="" /></div>
                                <div style={{ color: "#505050" }}>Combo Box</div>
                              </div>
                              <div style={{ color: "#505050" }}>
                                {formFieldDetails?.formFields?.length > 0 ? <>
                                  {(formFieldDetails?.formFields?.filter((itm) => itm.fieldType === "ComboBoxFormField")).length}
                                </>
                                  : 0}
                              </div>
                            </div>
                            <div style={{ display: "flex", padding: "10px 22px", justifyContent: "space-between" }}>
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div><img src="/Ellipse_red.svg" alt="" /></div>
                                <div style={{ color: "#505050" }}>Digital Signature</div>
                              </div>
                              <div style={{ color: "#505050" }}>
                                {formFieldDetails?.formFields?.length > 0 ? <>
                                  {(formFieldDetails?.formFields?.filter((itm) => itm.fieldType === "SignatureFormField")).length}
                                </>
                                  : 0}
                              </div>
                            </div>
                          </>
                        }
                        { openSettingsPanel &&
                          <div style={{padding: "10px 22px", display: "flex", flexDirection: "column", gap: "8px"}}>
                            <div style={{paddingBottom: "7px", color: "#505050"}}>Either replace existing PDF or add more pages</div>
                            <div>
                              <Button className="custom_button apply_button" style={{width: "100%"}} onClick={() => {setOpenReplacePdf(true)}}>Replace PDF</Button>
                            </div>
                            <div>
                              <Button className="custom_button cancel_button" style={{width: "100%"}} onClick={() => {
                                if(addPageRef.current){
                                  addPageRef.current.click();
                                }
                              }}>Add More Pages</Button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </Drawer>
                }
              </div>
              {showZoomActions && !templatePublished && (
                <>
                  <input
                    style={{ display: 'none' }}
                    ref={addPageRef}
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => addPageDocument(e.target.files)}
                    onClick={(e) => (e.target.value = "")}
                  />
                  <ZoomButtons zoomLevel={currentZoomLevel}/>
                  <div className="sheet-file-view-3"> {/* style={selectedIssueAnno?.Id ? { right : '470px' } : {}} */}
                    <div className="frame-wrapper" style={{ height: "auto" }}>
                      <div className="frame-3">
                        <div className="tool-button"  style={{ backgroundColor: comboToolActive ? "#E8EFFF"
                            : currentToolMode == "Pan" ? "#E8EFFF" : currentToolMode == "AnnotationEdit" ? "#E8EFFF" : "", }} >
                        <Tooltip title={ comboToolActive ? "" : currentToolMode == "Pan" ? "Pan" : "Multi Select"} placement="left" >
                          <div className="overlap-group-2">
                            <div id="combo-tool-button" onClick={()=> {setComboToolButton(!comboToolActive)}} className="img-wrapper" >
                              <img
                                className="img"
                                alt="Single select"
                                src={ currentToolMode == "Pan" ? "/pan.svg"
                                      : "/single select.svg" }
                              />
                            </div>
                            <img className="arrow-drop-down" alt="Arrow drop down" src="arrow_drop_down.svg" />
                          </div>
                        </Tooltip>
                        </div>
                        <img className="line" alt="Line"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64ad0840ca8a2bb608c26088/releases/64d1db41854be05d50e2e7c9/img/line-29.svg"
                        />
                        <div className="tool-button">
                          <Tooltip title="Text" placement="left" >
                            <div id="text_field_button" className="img-wrapper" 
                              style={{backgroundColor:currentToolMode == "TextFormFieldCreateTool" && "#E8EFFF"}}>
                              <img className="img" alt="Pencil" src="/formaddtextfield.svg"/>
                            </div>
                          </Tooltip>
                        </div>
                        <Tooltip title="Date" placement="left">
                          <div id="date_field_button" className="buttons-an" 
                            style={{backgroundColor:currentToolMode == "TextFormFieldCreateTool2" && "#E8EFFF"}}>
                            <img className="img" alt="Text" src="/due date.svg" />
                          </div>
                        </Tooltip>
                        <Tooltip title="Checkbox" placement="left">
                          <div id="checkbox_field_button" className="buttons-an"
                            style={{backgroundColor:currentToolMode == "CheckBoxFormFieldCreateTool" && "#E8EFFF"}}>
                            <img className="img" alt="Callout" src="/checkbox.svg" />
                          </div>
                        </Tooltip>
                        <Tooltip title="Radio" placement="left">
                          <div id="radio_field_button" className="buttons-an"
                            style={{backgroundColor:currentToolMode == "RadioButtonFormFieldCreateTool" && "#E8EFFF"}}>
                            <img className="subtract" alt="Subtract" src="/radiobutton.svg" />
                          </div>
                        </Tooltip>
                        <Tooltip title="List-box" placement="left">
                          <div id="listbox_field_button" className="buttons-an"
                            style={{backgroundColor:currentToolMode == "ListBoxFormFieldCreateTool" && "#E8EFFF"}}>
                            <img className="subtract" alt="Subtract" src="/combobox.svg" />
                          </div>
                        </Tooltip>
                        <Tooltip title="Combo-box" placement="left">
                          <div id="combobox_field_button" className="buttons-an"
                            style={{backgroundColor:currentToolMode == "ComboBoxFormFieldCreateTool" && "#E8EFFF"}}>
                            <img className="subtract" alt="Subtract" src="/combobox.svg" />
                          </div>
                        </Tooltip>
                        <Tooltip title="Signature" placement="left">
                          <div id="signature_field_button" className="buttons-an"
                            style={{backgroundColor:currentToolMode == "SignatureFormFieldCreateTool" && "#E8EFFF"}}>
                            <img className="img" alt="Color selector" src="/signature.svg" />
                          </div>
                        </Tooltip>
                        <img
                          className="line"
                          alt="Line"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64ad0840ca8a2bb608c26088/releases/64d1db41854be05d50e2e7c9/img/line-29.svg"
                        />
                        <Tooltip title="Undo" placement="left">
                          <div className="buttons-an">
                            <img
                              className="img"
                              alt="Undo"
                              src="/undo.svg"
                            />
                          </div>
                        </Tooltip>
                        <Tooltip title="Redo" placement="left">
                          <div className="buttons-an">
                            <img
                              className="img"
                              alt="Redo"
                              src="/redo.svg"
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    {comboToolActive && (
                      <div className="drawer-pan">
                        <div className="rectangle"/>
                        <Tooltip title="Pan" placement="top">
                          <div onClick={() => setComboToolButton(false)} id="pan-tool-button" className="buttons-4a"
                            style={{  backgroundColor: currentToolMode == "Pan" && "#E8EFFF", }} >
                            <img className="img" alt="Pencil" src="pan.svg" />
                          </div>
                        </Tooltip>
                        <Tooltip title="Multi Select" placement="top">
                          <div id="multi-tool-button" className="highlighter-wrapper"
                            style={{ backgroundColor: currentToolMode == "AnnotationEdit" && "#E8EFFF", }}
                            onClick={() => setComboToolButton(false)} >
                            <img className="img" alt="Highlighter"
                              src="https://anima-uploads.s3.amazonaws.com/projects/64ad0840ca8a2bb608c26088/releases/64e33ae27d1705404f2bd212/img/single-select.svg"
                            />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </>
              )}
              {!hidePdfContainer &&
                <div id="extractPDFcontainer" className="webviewer" ref={viewer} style={{ width: "100%", height: "94vh" }} />
              }
            </Box>
          </div>
      </div>
    </Dialog>
    {openReplacePdf &&
      <Dialog open={true} maxWidth="auto">
        <Paper sx={{ width: "548px" }}>
          <div className="template_steps">
            <div className="template_steps_head">
              <div className="template_steps_right">
                <div className="template_steps_right_title">
                  Replace PDF Template
                </div>
              </div>
              <div className="template_steps_left" onClick={onCloseReplacePdf}>
                <img src={CloseIcon} alt="icons" />
              </div>
            </div>
            <div className="template_steps_body" style={{ height: currentStep === 1 && "auto" }}>
              <>
                <div className="template_steps_body_text">
                  Replace PDF form. Any smart features within the PDF will be preserved.
                </div>
                <div className="upload_holder">
                  <section className="dropZone_container">
                    <div {...getRootProps({ className: "dropzone_2" })}>
                      {!toReplaceDocument[0] &&
                        <div className="upload_holder">
                          <input {...getInputProps()} />
                          <img src={UploadIcon} alt="upoad_icon" />
                          <p className="drag_drop">
                            Drag and Drop or{" "}
                            <span className="blue_upload">Choose File</span> to Upload
                          </p>
                          <p className="upload_p">File supported: pdf </p>
                        </div>
                      }
                      {toReplaceDocument && toReplaceDocument[0] && toReplaceDocument[0].fileName &&
                        <div className="upload_holder">
                          <input {...getInputProps()} />
                          <img src="/check-green.svg" alt="upoad_icon" style={{ width: "24px", height: "24px" }} />
                          <div style={{ color: "#0AA110", fontWeight: 400, fontSize: "14px" }}>
                            Upload Completed
                          </div>
                          <div style={{ color: "#000000", fontWeight: 600, fontSize: "14px" }}>
                            {toReplaceDocument[0].fileName}
                          </div>
                          <div style={{ color: "#346EF5", fontWeight: 400, fontSize: "14px" }}>Replace</div>
                        </div>
                      }
                    </div>
                    <aside>
                    </aside>
                  </section>
                </div>
              </>
            </div>
            <div className="template_steps_footer">
              <div className="template_steps_footer_button">
                <Button className="custom_button cancel_button" onClick={onCloseReplacePdf}>Cancel</Button>
                <Button className="custom_button apply_button" disabled={!toReplaceDocument[0]} onClick={onAcceptReplacePdf}>Replace PDF</Button>
              </div>
            </div>
          </div>
        </Paper>
      </Dialog>
    }
    {openConfirmReplace  &&
      <ConfirmDialogCheckbox
        credential={userData}
        nameOfDontShowProperty= {'pdfSkipReplacePdfDialog'}
        DialogData={confirmReplacePdfDialog}
        onAccept={() => {
          handleReplacePdf();
        }}
        onCancel={() => {
          setOpenConfirmReplace(false);
          setOpenReplacePdf(true);
        }}
      />
    }
    {openFormDetails &&
      <PdfTemplateDetail
        sfObj={sfObj}
        credential={credential}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        splitButtonAction={splitButtonAction}
        templateOptions={_templateOptions}
        onCancel={() => {
          onCancel();
          setFormDetail(false);
        }}
        onAccept={async (sfreocrd, selectedFile) => {
          setSpinner(true);
          let rec = cloneDeep(sfreocrd);
          setFormDetail(false);
          const formData = new FormData();
          formData.append("templateFile", selectedFile.file, selectedFile.fileName);
          formData.append("fileTitle", selectedFile.fileName);
          formData.append("putOnAmazon", true);
          formData.append("formRec", JSON.stringify(rec));
          await axios.post(`${fetchUrl}/saveFormTemplate/Form_Template__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
            .then((res) => {
              if (!sfreocrd.Id) {
                setTemplateUpdated(true);
                const formData2 = new FormData();
                //let issueObj = sfIssueObj;
                rec.Id = res.data.id;
                setVariables(rec);
                let rec2 = { Form_Template__c: rec.Id, Project__c: credential?.projectId };
                formData2.append("recObj", JSON.stringify(rec2));
                axios.post(`${fetchUrl}/recSave/Project_Form_Template__c`, formData2).then(async (res) => {
                  setProjectTemplateId(res.data.retObj.id);
                  getTemplateDetailsFromServer(rec.Id);
                })
              }
            })
        }}
      />
    }
    {toastMessage &&
      <ToastComponent message={toastMessage} handleClose={() => setToastMessage(null)} />
    }
  </>);
}
export default BuildPdfTemplate;