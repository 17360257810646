import { useState, useEffect, useRef } from "react";
import { Tooltip, Box, Button, IconButton, TextField, InputAdornment, Checkbox, Stack, Paper, Menu, Grid, } from "@mui/material";
import { Search } from "@mui/icons-material";

const MediaComponent = (props) => {
    const [openDetail, setOpenDetail] = useState(false);
    const [selectedMediaIds, setSelectedMediaIds] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchBoxFilter, setSearchBoxFilter] = useState(false);
    const [filterOptionsVisible, showFilterOptions] = useState(false);
    const [totalFilterApplied, setTotalFilterApplied] = useState(false);


    const handleDeselectMediaIds = (rowId, isChecked) => {
        setSelectedMediaIds([])
    };

    const handleClearAllFilters = () => {
    };

    const handleCheckboxChange = (index) => {
        setSelectedMediaIds((prevSelected) =>
            prevSelected.includes(index)
                ? prevSelected.filter((i) => i !== index) // Uncheck: remove index
                : [...prevSelected, index] // Check: add index
        );
    };

    return (
        <div className="wrapper_main">
            <div className="table_head">
                <div className="header_heading">
                    <h1>Media</h1>
                </div>
                <div className="right_side">
                    <Button component="button" className="custom_button apply_button" onClick={() => { }} >
                        Upload
                    </Button>
                </div>
            </div>
            <div className="page_body" style={{ width: openDetail && "calc(100% - 400px)" }}>
                <div style={{ width: "100%" }}>
                    <div className="page_body_right_head">
                        <div className="page_body_right_head_left_side">
                            <>
                                <div style={{ display: "flex", alignSelf: "center", marginRight: "10px", borderRight: "1px solid #EAEAEA" }} >
                                    {selectedMediaIds.length > 0 && (
                                        <div className="buttons" style={{ paddingBottom: "4px", cursor: "pointer", }} onClick={() => {
                                            handleDeselectMediaIds();
                                        }} >
                                            <img className="close" src="close.svg" alt="close" style={{ cursor: "pointer" }} />
                                        </div>
                                    )}
                                    <div style={{ marginRight: "10px" }}>
                                        <h2>{selectedMediaIds.length} Selected</h2>
                                    </div>
                                </div>
                                {selectedMediaIds.length > 0 && (
                                    <div style={{ borderRight: "1px solid #EAEAEA", borderTop: "1px solid #EAEAEA", borderBottom: "1px solid #EAEAEA", borderLeft: "1px solid #EAEAEA", display: "flex", }} >
                                        <div style={{ borderRight: "1px solid #EAEAEA" }}>
                                            <Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Export" placement="top" arrow>
                                                <IconButton sx={{ borderRadius: 0 }}>
                                                    <img src="/download.svg" alt="share" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{ borderRight: "1px solid #EAEAEA" }}>
                                            <Tooltip
                                                title="Share"
                                                placement="top"
                                                PopperProps={{ className: "version-action-tooltip" }}
                                                arrow
                                            >
                                                <IconButton
                                                    sx={{ borderRadius: 0 }}
                                                >
                                                    <img src="/share.svg" alt="share" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{ borderRight: "1px solid #EAEAEA" }}>
                                            <Tooltip
                                                title="Delete"
                                                placement="top"
                                                PopperProps={{ className: "version-action-tooltip" }}
                                                arrow
                                            >
                                                <IconButton
                                                    sx={{ borderRadius: 0 }}
                                                >
                                                    <img src="/delete.svg" alt="download" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )}
                            </>
                        </div>

                        <div className="page_body_right_head_right_side">
                            <div>
                                <TextField
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            color: "#505050",
                                            height: "37px",
                                            padding: "4px",
                                            width: "300px",
                                        },
                                    }}
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={searchText}
                                    fullWidth
                                    className="Searchbar-field"
                                    autoComplete="off"
                                    placeholder="Search Photo"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                {searchBoxFilter ? (
                                                    <img
                                                        style={{ width: "25px", cursor: "pointer" }}
                                                        src="/cancel.svg"
                                                        alt="close icon"
                                                        onClick={() => {
                                                            //  searchListViewFunction("");
                                                            setSearchText("");
                                                        }}
                                                    />
                                                ) : (
                                                    <Search />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        //convert input text to lower case
                                        var lowerCase = e.target.value.toLowerCase();
                                        if (!lowerCase || lowerCase === null || lowerCase === "") {
                                            // searchListViewFunction(lowerCase);
                                        }
                                        // setSearchText(lowerCase);
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            // Do code here
                                            e.preventDefault();
                                            var lowerCase = e.target.value.toLowerCase();
                                            //console.log('searchText ',searchText , 'lowerCase',lowerCase);
                                            //   searchListViewFunction(searchText);
                                        }
                                    }}
                                />
                            </div>
                            <div className={"grid"} style={{ width: "114px" }}>
                                <img className="filter2" src="/sort.svg" alt="filter2" />
                                <div className="label-11 poppins-normal-abbey-14px">
                                    Sort By
                                </div>
                            </div>
                            <div
                                className={totalFilterApplied > 0 ? filterOptionsVisible ? "grid-11" : "grid-111"
                                    : filterOptionsVisible ? "grid-1" : "grid"}
                                onClick={() => {
                                    showFilterOptions(!filterOptionsVisible);
                                }}
                            >
                                <img className="filter2" src="/filter2 (1).svg" alt="filter2" />
                                <div className="label-11 poppins-normal-abbey-14px">
                                    Filters{" "} {totalFilterApplied > 0 && (<>{" (" + totalFilterApplied + ")"}</>)}
                                </div>
                                {!filterOptionsVisible && totalFilterApplied > 0 && (
                                    <div className={filterOptionsVisible ? "grid-1" : "grid-2"} onClick={handleClearAllFilters}>
                                        <div className="clear1">
                                            <img className="clear" src="close.svg" alt="close" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '16px', paddingLeft: '20px', paddingRight: '20px', overflowX: 'auto', height: 'calc(100vh - 150px)' }}>
                        <Grid container spacing={2}>
                            {Array.from(Array(20)).map((_, index) => (
                                <Grid item xs={2.4} key={index}>
                                    <div
                                        className="gridContainer"
                                        style={{
                                            position: 'relative',
                                            backgroundColor: selectedMediaIds.includes(index) && '#E8EFFF', // Change bg color if selected
                                            borderColor: selectedMediaIds.includes(index) && '#346EF5',
                                        }}
                                    >
                                        <div
                                            className={selectedMediaIds.includes(index) ? "checkboxContainer" : 'checkboxContainerDisable'}
                                            style={{ position: 'absolute', top: '24px', left: '26px' }}
                                        >
                                            <Checkbox
                                                style={{ padding: '0px', backgroundColor: 'white', cursor: 'pointer' }}
                                                checked={selectedMediaIds.includes(index)} // Check state if index is selected
                                                onChange={() => handleCheckboxChange(index)} // Handle check/uncheck
                                            />
                                        </div>
                                        <img src="/mediaThumbnail.png" alt="media thumbnail" width="100%" />
                                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '8px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                <div style={{ fontWeight: 400, color: 'black' }}>File Name</div>
                                                <div style={{ fontWeight: 400, color: '#505050' }}>10/21/2023</div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <img src="/more sub nav.svg" alt="more options" />
                                                </div>
                                                <div style={{ display: 'flex', gap: '8px' }}>
                                                    <img src="/issues.svg" alt="issues" />
                                                    <img src="/form.svg" alt="form" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default MediaComponent;