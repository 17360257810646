import { createGlobalStyle } from "styled-components";

const NewGlobalStyled = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
 
:root{
    --btn-blue:#1976d2;
    --disable: #a5a5a5;
    --common-font: 'Poppins', sans-serif; 
    --drblue: #346EF5;
    --drgray: #929292;
    --border-gray: #D8D8D8; 
    --text-color: #505050;
    --body-emphasize-font-family: "Poppins-SemiBold", Helvetica;
    --body-emphasize-font-size: 14px;
    --body-emphasize-font-style: normal;
    --body-emphasize-font-weight: 600;
    --body-emphasize-letter-spacing: 0.14px;
    --body-emphasize-line-height: normal;
    --body-regular-font-family: "Poppins-Regular", Helvetica;
    --body-regular-font-size: 14px;
    --body-regular-font-style: normal;
    --body-regular-font-weight: 400;
    --body-regular-letter-spacing: 0.14px;
    --body-regular-line-height: normal;
    --body-small-font-family: "Poppins-Regular", Helvetica;
    --body-small-font-size: 12px;
    --body-small-font-style: normal;
    --body-small-font-weight: 400;
    --body-small-letter-spacing: 0.12px;
    --body-small-line-height: normal;
    --brandblue: rgba(52, 110, 245, 1);
    --brandlight-blue: rgba(232, 239, 255, 1);
    --dropdown: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    --neutralsblack: rgba(0, 0, 0, 1);
    --neutralsdark-grey: rgba(80, 80, 80, 1);
    --neutralslight-grey-2: rgba(234, 234, 234, 1);
    --neutralslight-grey-3: rgba(216, 216, 216, 1);
    --neutralswhite: rgba(255, 255, 255, 1);
    --on-hover: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    --statusred: rgba(249, 40, 40, 1);
    --body-emphasize-font-family: "Poppins-SemiBold", Helvetica;
    --body-emphasize-font-size: 14px;
    --body-emphasize-font-style: normal;
    --body-emphasize-font-weight: 600;
    --body-emphasize-letter-spacing: 0.14px;
    --body-emphasize-line-height: normal;
    --body-regular-font-family: "Poppins-Regular", Helvetica;
    --body-regular-font-size: 14px;
    --body-regular-font-style: normal;
    --body-regular-font-weight: 400;
    --body-regular-letter-spacing: 0.14px;
    --body-regular-line-height: normal;
    --body-small-font-family: "Poppins-Regular", Helvetica;
    --body-small-font-size: 12px;
    --body-small-font-style: normal;
    --body-small-font-weight: 400;
    --body-small-letter-spacing: 0.12px;
    --body-small-line-height: normal;
    --brandblue: rgba(52, 110, 245, 1);
    --brandlight-blue: rgba(232, 239, 255, 1);
    --neutralsblack: rgba(0, 0, 0, 1);
    --neutralsdark-grey: rgba(80, 80, 80, 1);
    --neutralsgrey: rgba(146, 146, 146, 1);
    --neutralslight-grey-1: rgba(248, 248, 248, 1);
    --neutralslight-grey-2: rgba(234, 234, 234, 1);
    --neutralslight-grey-3: rgba(216, 216, 216, 1);
    --neutralswhite: rgba(255, 255, 255, 1);
    --on-hover: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    --statusgreen: rgba(10, 161, 16, 1);
    --statusred: rgba(249, 40, 40, 1);
    --statusstatus: rgba(255, 170, 43, 1);
    --neutralslight-grey-4: rgba(186, 186, 186, 1);
  }
}
*{
    font-family: var(--common-font);
}
html {
  overflow: auto;
}
.children {
    padding-left: 16px;
}
.css-ahj2mt-MuiTypography-root {
    font-family: var(--common-font);
}
[role="tablist"] {
  gap: 24px;
    button {
    padding: 10px 0;
    font-family: var(--common-font); 
    font-size: 14px;
    color: var(--drgray);
    text-transform: capitalize;
}
.Mui-selected{
  color: var(--drblue);
  font-weight: 500;
} 
}
.MuiPopover-root .project-name .MuiMenu-list {
  max-height:700px;
  overflow:auto;
}
.MuiPopover-root .MuiMenu-list {
  max-height:350px;
  overflow:auto;
}
button.MuiButtonBase-root.Mui-disabled {
  color: white;
  border: none;
}
.Searchbar-field {
 .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
   border-color: var(--border-gray);
 }
  .MuiInputBase-root{ 
    background-color: white; 
   }
  &:hover{ 
      .MuiInputBase-root{ 
       background-color: #f8f8f8; 
      }
      .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
        border-color: var(--border-gray);
      }
  }
  .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      color: var(--text-color);
      border-color: var(--drgray);
    }
}
button.custom_button {
  box-shadow: none;
  font-weight: 400;
  font-family: var(--common-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 9px 16px;
  height: 39px;
  text-transform: none;
  border-radius: 2px;
}
button.cancel_button {
  &:hover {
    background: #F8F8F8;
  }
  border: 1px solid #505050;
  background: #FFFFFF;
  color: #505050;
  
}
button.apply_button {
  &:hover {
    background-color: var(--drblue);
  }
  border: none;
  background-color: var(--drblue);
  color: #fff;              
}
button.red_button {
  &:hover {
    background-color: #F92828;
  }
  color: white;
  background-color: #F92828;
  border: none;
}
.wrapper_main {
  padding-top: 0px;
}
.table_head.main_table {
  padding: 10px 16px;
}
.header_heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.right_side{
  .FilterButton{
  color: #505050;
  text-transform: capitalize;
  font-family: var(--common-font);
  font-weight: 400;
  font-size: 14px;
  &:hover {
      background: #E8EFFF;
      svg{
        fill: #505050;
         }
      }
  }
}
.tab_wrapper .MuiTabs-scroller{
  span.MuiTabs-indicator {
   background: var(--drblue);
   height: 1px;
  }
}
.page_body {
  display: flex;
}
.page_body_right_head {
  display: flex;
  padding: 10px 16px;  
  justify-content: space-between;
  width: 100%;
}
.page_body_right_head_left_side {
  //  border-right: 1px solid #EAEAEA;
    h2 {
      color: var(--drgray);
      font-size: 14px;
      line-height: 21px;
  }
  display: flex;
}
.page_body_right_head_right_side {
  label +.MuiInputBase-formControl .MuiOutlinedInput-input {
      height: 21px;
      padding: 8px 12px !important;
      line-height: normal;
      //color: #D8D8D8;
  }
  .MuiFormControl-root {
      label {line-height: normal;
          top: -7px;
          font-family: var(--common-font);
          font-size: 14px;
      }
      .MuiInputBase-formControl {
          border-radius: 2px;
      }
  }
  display: flex;
  gap: 10px;
}
  .grid {
    &:hover {
      background: #F8F8F8;
    }
    cursor: pointer;
    width: 90px;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid;
    border-color: #D8D8D8; 
    border-radius: 2px;
    display: inline-flex;
    gap: 8px;
    height: 37px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
}
.filter2 {
  height: 16px;
  position: relative;
  width: 16px;
}
.label-11 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px; 
  position: relative; 
  width: fit-content;
  color: #505050;
}
.grid-11 {
  &:hover {
    background: #F8F8F8;
  }
  cursor: pointer;
  width: 115px;
  align-items: center;
  background-color: #E8EFFF;
  border: 1px solid;
  border-color: #D8D8D8; 
  border-radius: 2px;
  display: inline-flex;
  gap: 8px;
  height: 37px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
}
.grid-111 {
  &:hover {
    background: #F8F8F8;
  }
  cursor: pointer;
  width: 140px;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid;
  border-color: #D8D8D8; 
  border-radius: 2px;
  display: inline-flex;
  gap: 8px;
  height: 37px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
}
.grid-1 {
  &:hover {
    background: #F8F8F8;
  }
  cursor: pointer;
  width: 90px;
  align-items: center;
  background-color: #E8EFFF;
  border: 1px solid;
  border-color: #D8D8D8; 
  border-radius: 2px;
  display: inline-flex;
  gap: 8px;
  height: 37px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
}
.grid-2 {
  &:hover {
    background: #F8F8F8;
  }
  width: 20px;
  align-items: center;
 // background-color: #FFFFFF;
  border-left: 1px solid;
  border-color: #D8D8D8; 
  border-radius: 2px;
 // display: inline-flex;
  height: 37px;
  justify-content: center;
 // overflow: hidden;
  padding: 8px 1px;
 // position: relative;
}
.clear{
  height: 16px;
  position: relative;
  width: 16px;
  margin-left: 7px;
}
.buttons {
  &:hover {
      background: #F8F8F8;
    }
  align-items: center; 
  border-radius: 2px; d
  isplay: flex;
  height: 24px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.switch_button {
  display: flex;
  margin-top: 1px
}
.switch_icon {
  &:hover {
      background: #F8F8F8;
    }
  border: 1px solid #D8D8D8;
  width: 35px;
  height: 35px;
  padding: 6px 6px 6px 10px;
  cursor: pointer;
} 
  .zoom-container {
    right: 15px;
    bottom: 15px;
    float: right;
    display: inline-flex;
    align-items: flex-start;
    gap: -1px;
    position: absolute;
  }
  .navigation-container {
    right: 45%;
    bottom: 15px;
    display: inline-flex;
    align-items: flex-start;
    gap: -1px;
    position: absolute;
    background: white;
  }
  .zoom-button {
    cursor: pointer;
    max-width: none !important;
    display: flex;
    height: 37px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid var(--neutrals-light-grey-3, #d8d8d8);
    background: var(--neutrals-white, #fff);
  }
  .naviThumbnail-layout {
    width:100%
    bottom: 0px;
    position: absolute;
    background: white;
    z-index:101;
    border: 1px solid #D8D8D8
    padding:2px;
  }
  .naviThumbnail-container {
    display : flex;
    padding:14px;
    gap:14px;
    overflow-x: auto;
  }
  .naviThumbnail-item {
    width:150px;
    padding:10px;
    border: 1px solid #D8D8D8;
    flex-shrink:0;
    border-radius:2px;
    cursor:pointer;
  }
  .thumb-item-current {
    border: 3px solid #346EF5;
    cursor:default;
  }
  .naviThumbnail-img {
    width:140px;
    height:100px;
    border-radius:2px;
  }
  .naviThumbnail-sheetName {
    overflow: hidden;
    color: var(--Neutrals-Black, #000);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 8px
  }
  .nav-side-overlay-button {
    position:absolute;
    width:40px;
    height:170px;
    display:flex;
    flex-shrink:0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .nav-side-overlay-button > img {
    width:25px;
    height:25px;
  }
  .nav-side-overlay-button.nav-left {
    left:0;
    background-image: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1));
  }
  .nav-side-overlay-button.nav-right {
    right:0;
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
  }
  .click-disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
.zoom-container-1{
  right: 1000px;
  bottom: 15px;
  float: right;
  display: inline-flex;
  align-items: flex-start;
  gap: -1px;
  position: absolute;
}
.disable_ele {
  filter: opacity(0.5);
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

  .queue-body-modal{
    position: fixed;
    z-index: 99;
    right: 16px;
    bottom: 4px;
    width: 400px;
    border: 1px solid #EAEAEA;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
  }
  .queue-body-header{
    display: flex;
    padding: 5px 14px;
    border: 1px solid #EAEAEA;
    background: #F8F8F8;
    justify-content: space-between;
    align-items: center;
    color: #505050;
    font-size: 14px;
    &_label {
      font-weight: 600;
    }
    &_action {
      display: flex;
      gap:5px
      .btn {
        background: #F8F8F8;
      }
    }
  }
  .queue-body-container{
    padding: 5px 0px;
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
  }
  .queue-body-list-item{
    display: flex;
    padding: 0px 16px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    &_label {
      font-weight: 400;
      width : 90%;
      &_icon {
        min-width: 20px;
      }
    }
    &_status {
      flex-shrink:0;
    }
  }
.file-upload-progress-wrapper > * {
  position: relative 
  width: 100% !important; 
  height: auto !important; 
  z-index: 2 !important; 
}

.export-indicator-wrapper > * {
  position: relative !important; 
  width: 100% !important; 
  height: auto !important; 
  z-index: 1 !important; 
}
.gridContainer {
  padding: 12px;
  border: 1px solid var(--Medium-Grey, #D8D8D8);
  border-radius: 2px;
}
.checkboxContainerDisable {
     display: none;
     position: absolute;
     top: 24px; 
     left: 26px
  }
}
.checkboxContainer {
     display: block;
     position: absolute;
     top: 24px; 
     left: 26px
  }
}
.gridContainer:hover .checkboxContainerDisable {
      display: block !important;
}
`;

export default NewGlobalStyled;
