import { useState, useEffect, useRef } from "react";
import CloseIcon from "assets/images/close.svg";
import moment from "moment";
import axios from "axios";
import { fetchUrl } from "Urls";
import Spinner from "components/spinner";
import _, { find } from "lodash";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import errorRed from "assets/images/error-red.svg";
import { AddPdfFile } from "./IssuesPop";
import IssuePdfView from "./IssuePdfView";
import { PublishIssueDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import {
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  InputAdornment
} from "@mui/material";
export const SaveIssueDialog = ({ ...props }) => {
  const { credential, onAccept,  onCancel, linkedDocumentOption , saveCallback, callFrom, responeId} = props;
  const [showSpinner, setSpinner] = useState(false);
  const [requiredMissing, setRequiredMissing] = useState(true);
  const [issueDueDate, setIssueDueDate] = useState(new Date());
  const [sfRecord, setsfRecord] = useState(defaultObj());
  const [openAddFile, setAddFile] = useState(false);
  const [linkDocument, setLinkDocument] = useState("");
  const [startLinkDocumnet, setStartLinkDocumnet] = useState(false);
  const [publishIssueDialog, setPublishIssueDialog] = useState(false);
  const [folderNameToIdMap, setSubFolderMap] = useState(null);
  function defaultObj() {
    if(responeId) {
    return {
      Project__c : credential?.projectId,
      Form_Response__c : responeId
    };
  }else {
    return {
      Project__c : credential?.projectId
    };
  }
}
useEffect(() => {
  if (credential?.projectId) {
    getSourceFolder();
  }
}, [credential?.projectId]);
  function setVariables(_sfObj) {
    if (_sfObj.Due_Date__c) {
      setIssueDueDate(new Date(_sfObj.Due_Date__c));
    } else {
      setIssueDueDate(null);
    }
    setRequiredMissing(!checkReadyToSave(_sfObj));
    setsfRecord(JSON.parse(JSON.stringify(_sfObj)));
  }
  function checkReadyToSave(_sfObj) {
    //console.log('checkReadyToSave');
    let reqfields = ["Name"];
    if (_sfObj) {
      for (let f of reqfields) {
        if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
          return false;
        }
      }
    }
    return true;
  }
  async function onClickSave(issueObj, info) {
    issueObj["Project__c"] = credential?.projectId;
    console.log('issueObj :: ',JSON.stringify(issueObj));
    setSpinner(true);
    await saveCallback(issueObj , info);
    setSpinner(false);
  }
  async function getSourceFolder() {
    let subFoldermap;
    await axios.get(`${fetchUrl}/sourceFolder/Plan/${credential?.projectId}`)
      .then((res) => {
        if (res?.data?.status === 200) {
          const { tree } = res?.data;
          if (tree?.sub_folders?.length > 0) {
            for (let fol of tree?.sub_folders) {
              if (!subFoldermap) {
                subFoldermap = {};
              }
              if (!subFoldermap[fol.Name]) {
                subFoldermap[fol.Name] = fol;
              }
            }
          }
        }
      }).catch((err) => {
        console.log("/sourceFolder_/", err);
      });
    setSubFolderMap(subFoldermap);
    setSpinner(false);
  }
  return (
    <>
      <Dialog open={true} PaperProps={{ sx: { width: "560px", maxHeight: "679px" }}}>
        <DialogTitle className="dialog_title" style={{ padding: "16px 20px" }} >
          Create Issue
          <IconButton aria-label="close" onClick={onCancel} sx={{ position: "absolute", right: 12 }} >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content" style={{ padding: "16px 20px" }} >
          {showSpinner && <Spinner />}
          <div>
            <div className="issue_label_name">Issue Name</div>
            <div>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    color: "#505050",
                    height: "37px",
                    padding: "4px",
                    width: "515px",
                    borderColor: "red",
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                className={!sfRecord?.Name ? "Searchbar-field-red" : "Searchbar-field"}
                autoComplete="off"
                fullWidth
                inputProps={{ maxLength: 80, style: { borderColor: "red" }, }}
                value={sfRecord?.Name ? sfRecord?.Name : ""}
                required
                onChange={(e) => {
                  let fff = sfRecord;
                  //console.log("fff ::", fff);
                  const invalidCharacters = /["/:|<>*@?\\]/g;
                  const newValue = e.target.value.replace(invalidCharacters, "");
                  fff.Name = newValue;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
              <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400, }} >
                {!sfRecord?.Name && (
                  <div>
                    <img style={{ marginRight: "5px" }} src={errorRed} alt="error" />
                    This is required. Please input an Issue Name.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="issue_label_name">Type</div>
            <div>
              <Select id="type" style={{ height: "37px", width: "515px", color: "#505050", }}
                value={sfRecord?.Type__c ? sfRecord?.Type__c : ""}
                onChange={(event) => {
                  let fff = sfRecord;
                  fff.Type__c = event?.target?.value;
                  setVariables(fff);
                }}
              >
                {credential?.issueObjOptions?.Type__c?.options &&
                  credential?.issueObjOptions?.Type__c?.options.map(
                    (opt, index) => (
                      <MenuItem key={opt.value} value={opt.value} >
                        {opt.label}
                      </MenuItem>
                    )
                  )}
              </Select>
            </div>
          </div>
          <div>
            <div className="issue_label_name">Description</div>
            <div>
              <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "74px", padding: "4px", width: "515px", }, }}
                inputProps={{ maxLength: 80 }}
                id="outlined-basic"
                variant="outlined"
                className="Searchbar-field"
                autoComplete="off"
                required
                onChange={(e) => {
                  let fff = sfRecord;
                  fff.Description__c = e.target.value;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div className="issue_label_name">
              Ball in Court
            </div>
            <div>
              <Select id="type" style={{ height: "37px", width: "515px", color: "#505050", }}
                value={sfRecord?.Ball_in_Court__c ? sfRecord?.Ball_in_Court__c : ""}
                onChange={(event) => {
                  let fff = sfRecord;
                  fff.Ball_in_Court__c = event?.target?.value;
                  if (fff.Ball_in_Court__c) {
                    let dsfIssue = find(credential?.userList, {value: fff.Ball_in_Court__c,});
                    fff.Ball_in_Court__r = {Id: event?.target?.value,Name: dsfIssue?.label};
                  }
                  setVariables(fff);
                }}
              >
                {credential?.userList &&
                  credential?.userList.map((opt, index) => (
                    <MenuItem key={opt.value} value={opt.value} data-label={opt.label} >
                      {opt.label}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
          <div>
            <div className="issue_label_name">Due Date</div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DesktopDatePicker
                  className="common_class"
                  disablePast={false}
                  placeholder="Due Date"
                  inputFormat="MM/dd/yyyy"
                  value={issueDueDate}
                  onChange={(newValue) => {
                    let fff = sfRecord;
                    if (newValue) {
                      fff.Due_Date__c = moment(newValue).format("YYYY-MM-DD");
                    } else {
                      fff.Due_Date__c = null;
                    }
                    setVariables(fff);
                  }}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        id="dueDate"
                        sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "507px", }}}
                        variant="outlined"
                        className="Searchbar-field"
                        autoComplete="off"
                        {...params}
                      />
                    </div>
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          {callFrom == "IssuesComponent" &&
            <div>
              <div className="issue_label_name">
                Linked Document
              </div>
              <div>
                {sfRecord && sfRecord?.Linked_Document__r && sfRecord?.Linked_Document__r.File_Name__c ? (
                  <TextField  sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "507px", }}}
                inputProps={{ maxLength: 2000 }}
                id="outlined-basic"
                variant="outlined"
                className="Searchbar-field"
                autoComplete="off"
                required
                placeholder="Search Sheets"
                value={sfRecord?.Linked_Document__r.File_Name__c}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                        <img
                          style={{ width: "25px", cursor: "pointer" }}
                          src="/close.svg"
                          alt="close icon"
                        onClick={() => {
                          let fff = sfRecord;
                          fff.Linked_Document__c = null;
                          fff.Linked_Document__r = null;
                          sfRecord.Annotation_Id__c = null;
                          setVariables(fff);
                        }}
                        />
                    </InputAdornment>
                  ),
                }}
              />
                ):(
                   <Select id="type" style={{ height: "37px", width: "515px", color: "#505050", }}
                   onChange={(event) => {
                     setLinkDocument(event?.target?.value);
                     if (event?.target?.value === 'File' || event?.target?.value === 'Sheet') {
                       setAddFile(true);
                     } else {
                       setAddFile(false);
                     }
                   }}>
                   {linkedDocumentOption &&
                     linkedDocumentOption.map((opt, index) => (
                       <MenuItem key={opt.value} value={opt.value}>
                         {opt.label}
                       </MenuItem>
                     ))}
                 </Select>
                )}
               
              </div>
            </div>
          }
          <div>
            <div className="issue_label_name">Location</div>
            <div>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    color: "#505050",
                    height: "37px",
                    padding: "4px",
                    width: "515px",
                    borderColor: "red",
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                className={!sfRecord?.Location_Details__c ? "Searchbar-field-red" : "Searchbar-field"}
                autoComplete="off"
                fullWidth
                value={sfRecord?.Location_Details__c ? sfRecord?.Location_Details__c : ""}
                required
                onChange={(e) => {
                  let fff = sfRecord;
                  //console.log("fff ::", fff);
                  const invalidCharacters = /["/:|<>*@?\\]/g;
                  const newValue = e.target.value.replace(invalidCharacters, "");
                  fff.Location_Details__c = newValue;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div className="issue_label_name">Root Cause</div>
            <div>
              <Select id="type" style={{ height: "37px", width: "515px", color: "#505050", }}
                value={sfRecord?.Root_Cause__c ? sfRecord?.Root_Cause__c : ""}
                onChange={(event) => {
                  let fff = sfRecord;
                  fff.Root_Cause__c = event?.target?.value;
                  setVariables(fff);
                }}
              >
                {credential?.issueObjOptions?.Root_Cause__c?.options &&
                  credential?.issueObjOptions?.Root_Cause__c?.options.map(
                    (opt, index) => (
                      <MenuItem key={opt.value} value={opt.value} >
                        {opt.label}
                      </MenuItem>
                    )
                  )}
              </Select>
            </div>
          </div>
          <div>
            <div className="issue_label_name">Resolution</div>
            <div>
              <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "515px", }, }}
                inputProps={{ maxLength: 255 }}
                id="outlined-basic"
                variant="outlined"
                className="Searchbar-field"
                autoComplete="off"
                required
                value={sfRecord?.Response__c ? sfRecord?.Response__c : ""}
                onChange={(e) => {
                  let fff = sfRecord;
                  fff.Response__c = e?.target?.value;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="action_button" style={{ padding: "16px 20px" }} >
          <Button className="custom_button cancel_button" onClick={onCancel} >
            Cancel
          </Button>
          <Button className="custom_button cancel_button" disabled={showSpinner || (!sfRecord?.Name ? true : requiredMissing)}
            onClick={() =>
              onClickSave(sfRecord, {saveAs :sfRecord.Id ? "Owner DraftId" : "Owner Draft new"})
            }>
            Save as Draft
          </Button>
          <Button className="custom_button apply_button" onClick={()=>setPublishIssueDialog(true)} 
            disabled={showSpinner || ((!sfRecord?.Ball_in_Court__c && !sfRecord?.Linked_Document__c && !sfRecord?.Annotation_Id__c) ? true : requiredMissing)} >
            Publish Issue
          </Button>
        </DialogActions>
      </Dialog>
      {openAddFile && linkDocument &&
        <AddPdfFile
          onCancel={() => {
            setLinkDocument("");
            setAddFile(false);
          }}
          onAccept={(fileData) => {
            let fff = sfRecord;
            fff.Linked_Document__c = fileData?.id;
            fff.Linked_Document__r = {Id : fileData?.id , File_Name__c : fileData?.name};
            setVariables(fff);
            setStartLinkDocumnet(true);
          }}
          credential={credential}
          linkDocument={linkDocument}
          folderNameToIdMap={folderNameToIdMap}
        />
      }
      {startLinkDocumnet && sfRecord?.Linked_Document__c && (
        <IssuePdfView
          credential={credential}
          issueRec={sfRecord}
          onCancel={() => {
            setStartLinkDocumnet(false);
          }}
          onAccept={(annotationId) => {
            let fff = sfRecord;
            fff.Annotation_Id__c = (annotationId ? annotationId : null);
            setVariables(fff);
            setStartLinkDocumnet(false);
            setAddFile(false);
          }}
          callFrom={linkDocument === "File" ? 'FilesComponent': 'SheetsComponent'}
        />
      )}
      {publishIssueDialog && 
        <PublishIssueDialog
          onCancel={()=>{
            setPublishIssueDialog(false);
          }}
          onAccept={async ()=>{
            let fff = sfRecord;
            fff.Status__c = 'Pending';
            setVariables(fff);
            setPublishIssueDialog(false);
            await onClickSave(fff , {saveAs:'Owner Publish'});
            onCancel();
          }}
        />
      }
    </>
  );
}
export default SaveIssueDialog;