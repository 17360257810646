import React from "react"
const ToastComponent = (props) => {
   const { message, handleClose, variant, action } = props;
   //console.log("variant ::", variant);
   React.useEffect(() => {
      setTimeout(function () {
        handleClose(false);
      }, 5000);
   }, []);
   const getBackgroundColor = () => {
      switch (variant) {
        case 'Information':
          return '#E8EFFF';
        case 'Warning':
          return '#FFF6EA';
        case 'Error':
          return '#F928280F';
        default:
          return '#EBFFEC';
      }
    };
  
    const getTextBorderColor = () => {
      switch (variant) {
        case 'Information':
          return '#346EF5';
        case 'Warning':
          return '#D88100';
        case 'Error':
          return '#F92828';
        default:
          return '#0AA110';
      }
   };
   return (
      <div className="toast-message" style={{backgroundColor: getBackgroundColor(), borderColor: getTextBorderColor(), color: getTextBorderColor()}}>
         <div style={{ width: "20px" }}>
            <img src={variant == "Information" ? "/info.svg" : variant == "Warning" ? "/error_orange.svg" : variant == "Error" ? "/error-red.svg" : "/check-green.svg"} alt="checkIcon" />
         </div>
         <div >{message}</div>
         <div style={{ marginLeft: "auto", display: "flex", gap: "20px" }}>
            {action &&
               <div >{action}</div>
            }
            <img src={variant == "Information" ? "/close_blue.svg" : variant == "Warning" ? "/close_orange.svg" : variant == "Error" ? "/close_red.svg" : "/closegreen.svg"} alt="" onClick={() => handleClose(false)} style={{ cursor: 'pointer' }} />
         </div>
      </div>
   );
};

export default ToastComponent;
