import React, { useMemo, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import 'react-tooltip/dist/react-tooltip.css';
import { makeStyles } from '@mui/styles';
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useSortBy,
  usePagination
} from "react-table";
import { MoreVert , KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import { IconButton, Button,  Dialog, DialogActions , Checkbox, MenuItem} from "@mui/material";
import { Select } from '@mui/material';
//console.log("reRender2");
const useStyles = makeStyles({
  customDialog: {
    width: 300,
    maxHeight: 300// Set the desired width in pixels
  },
});
const Styles = styled.div`
  width: 100%;
  padding: 0 10px;
  overflow-x: auto;
  overflow-y: auto;
  display: block;
  .table {
    border-spacing: 0;
    background: #fff;
    width: 100%;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    z-index: 0;
    div {
      color: rgba(0, 0, 0, 0.6);
      font-size: 13px;
    }
    .thead {
      overflow-y: auto;
      overflow-x: hidden;
      min-width: 100%;
      position: sticky;
      top: 0;
      z-index: 999;
      background: #fff;
    }
    .thead .tr {
      &:hover {
        background: #fff;
      }
    }
    .setting {
      &:hover {
        background: #F8F8F8;
      }
    }
    .tbody {
      background: #fff;
    }

    .tr {
      width: 100% !important;
      &:hover {
        background: #F8F8F8;
      }
      border-bottom: 1px solid #eaeaea;
    }
    .tbody .tr {
      &:last-child {
        border-bottom: 0;
      }
    }
    .selected {
      width: 100% !important;
      &:hover {
        background: #F8F8F8;
      }
      border-bottom: 1px solid #eaeaea;
      background: #E8EFFF;
    }
    .th {
      color: #505050;
      font-family: var(--common-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.14px;
      &:hover {
        background: #F8F8F8;
        .resizer { opacity: 1; }
      }
    }
    .settingColumn{
      overflow: unset !important;
      margin-left: auto !important;
    }
    .th,.td {
      color: #505050;
      margin: 0;
      padding: 0.5rem;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 91px;
      .name_main {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      position: relative;

      :last-child {
        border-right: 0;
      }
      .resizer {
        opacity: 0;
        right: 0;
        background: #717171;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        touch-action :none;
        &.isResizing {
          background: red;
        }
      }
    }
    div[title="Toggle SortBy"] {
      font-weight: 600;
    }
  }
  .hoverStyle {
    &:hover {
      background: #f8f8f8;
    }
    cursor: pointer;
  }
  .issuebottomLeft {
    width: 75px;
    border-color: #D8D8D8;
    border-radius: 2px;
    &:hover{ 
      .MuiOutlinedInput-notchedOutline  {
       border-color: #d8d8d8;
     }
     
 }
  }
  .issuebottomRight {
    margin-right: 9px;
    padding-left: 12px;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
    height: 35px;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color:  #d8d8d8 !important; 
     }
    .option-popup {
      position: absolute;
      top: 41px;
      right:0;
      background-color: white;
      z-index: 101; 
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    .option-container {
      display: flex;
      width: 264px;
      padding: 10px;
      gap:5px;
      flex-direction: column;
      align-items: flex-start;
    }
    .option-action {
      border-top: 1px solid #EAEAEA; 
      display: flex;
      padding: 12px;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 12px;
      align-self: stretch;
      .Mui-disabled {
        background: #BABABA;
        color: white;
        border: 2px solid #BABABA;
      }
    }
`;

const headerProps = (props, { column }) => getStyles(props , column);

const cellProps = (props, { cell }) => getStyles(props, cell.column, cell.column.className);

const getStyles = (props, column = {}, className = "" ) => [
  props,
  {
    style: {...{
      justifyContent: column?.id === 'settings' ? "center" :"space-between",
      alignItems: "center",
      display: "flex",
    },...column?.overrideStyle ? column?.overrideStyle : {}},
    className: "td " + className,
  },
];

function Table({ columns, data, handleClickMenu, openMenu, setCurrentFileId, selectedIds, NoDataComponent, listCount, setRowsPerPage, pagination, bottom, initPageIndex, onPageChange }) {
  // Use the state and functions returned from useTable to build your UI
  const defaultColumn = React.useMemo(() => ({}),[]);
  const [toHideColumns, setToHideColumns] = useState([]); // keeping state for custom UI for hiding columns
  const [hiddenColumns, setHiddenColumnState] = useState([]); // actuall Hidden Columns 
  const [tablePageNumber, setTablePageNumberState] = useState(0);
  const tablePageNumberRef = useRef(tablePageNumber);
  const setTablePageNumber = (state) =>{
    if(onPageChange){
      onPageChange(state);
    }
    tablePageNumberRef.current = state;
    setTablePageNumberState(state);
  };
  const tableRef = useRef(null);
  const { getTableProps, headerGroups, rows, prepareRow, page,
          canNextPage, canPreviousPage, pageOptions, state, gotoPage, pageCount,
          setHiddenColumns,
          setPageSize, allColumns } = useTable({ columns, data, defaultColumn,
                                                  initialState: { hiddenColumns : hiddenColumns,sortBy: [], pageIndex : (initPageIndex > -1 ? initPageIndex : 0) ,pageSize: 100 } },
                                                  useBlockLayout,useResizeColumns, useSortBy, usePagination );
  const filteredColumns = allColumns.slice(1, -1);
  useEffect(() => {
    const startIndex = state.pageIndex * state.pageSize;
    const endIndex = startIndex + state.pageSize;
    //console.log('startIndex ::',startIndex,'endIndex ::',endIndex);
    const originalDataForPage = rows.slice(startIndex, endIndex).map(row => row.original);
    setRowsPerPage(originalDataForPage);
  }, [state.pageIndex, rows?.length, state.pageSize])
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'instant' });
    }
  }, [state.pageIndex]);
  //console.log("columns", columns);
  //console.log("state", state, "pageCount", pageCount, page);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const toggleColumnSettings = () => {
    setPopoverOpen(prevState => !prevState);
  };
  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };
  const handleGoToPage= (p_num) => {
    setTablePageNumber(p_num);
    gotoPage(p_num);
  };
  return (
    <>
      <table {...getTableProps()} className="table" ref={tableRef}>
        <thead className="thead" >
          {headerGroups.map((headerGroup, hind) => (
            <tr key={headerGroup.id + hind} className={"tr"} {...headerGroup.getHeaderGroupProps({})} >
              {headerGroup.headers.map((column, columnIndex) => (
                <th key={headerGroup.id + column.Header + columnIndex}
                  {...column.getHeaderProps(headerProps)}
                  className={`th${column.id === 'settings'?' settingColumn' : ''}`} >
                  {column.id === 'settings' ? (
                    <>
                      <div style={{ color: "white", cursor: 'pointer', textAlign: 'end' }} className="setting" id="columnSettingsButton" onClick={toggleColumnSettings}>
                        <img style={{ height: "20px" }} src="/settings.svg" alt="Settig" />
                      </div>
                      {isPopoverOpen &&
                        <>
                          <div className="option-popup">
                            <div className="option-container">
                              {filteredColumns.map((column) => {
                                let isHidden = (toHideColumns.filter((row) => row === column?.id)?.length > 0);
                                return (
                                  <div key={column.id}>
                                    <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: '8px' }}>
                                      <Checkbox
                                        style={{ padding: "0px", marginLeft: "10px" }}
                                        sx={{ "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
                                        type="checkbox"
                                        className="checkbox"
                                        checked={isHidden ? false : true}
                                        onChange={(e) => {
                                          setToHideColumns(prevHiddenColumns => {
                                            if (prevHiddenColumns.includes(column.id)) {
                                              return prevHiddenColumns.filter(col => col !== column.id);
                                            } else {
                                              return [...prevHiddenColumns, column.id];
                                            }
                                          });
                                        }}
                                      />
                                      {' '}<span style={{ color: '#505050' }}>{column.Header}</span>
                                    </label>
                                  </div>)
                              })}
                            </div>
                            <div className="option-action">
                              <div style={{ paddingLeft: '10px', alignSelf: 'center', marginRight: 'auto' }} >
                                <span style={{ cursor: 'pointer' }} onClick={() => setToHideColumns([])}>Reset</span>
                              </div>
                              <Button className="custom_button cancel_button" onClick={() => {
                                setPopoverOpen(false);
                                setToHideColumns(hiddenColumns); // reseting to actual hidden columns 
                              }}>
                                Cancel
                              </Button>
                              <Button className="custom_button apply_button" onClick={(e) => {
                                setHiddenColumnState(toHideColumns);
                                setHiddenColumns(toHideColumns); // library method to update hidden columns values
                                setPopoverOpen(false);
                              }}>
                                Apply
                              </Button>
                            </div>

                          </div>
                          <div className="outer-div" onClick={() => setPopoverOpen(false)}></div>
                        </>
                      }
                    </>
                  ) : (
                    <div {...column.getHeaderProps( column.getSortByToggleProps() )} title={undefined} style={column.canSort ? {cursor:'pointer'}:{}}>
                      <div style={{display : 'flex'}}>
                        <span onClick={(e)=>{if(column.canSort){ e.preventDefault(); e.stopPropagation(); }}}>
                          {column.render("Header")}
                        </span>
                        <span style={{marginLeft : 'auto'}}>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <KeyboardArrowDown />
                            ) : (
                              <KeyboardArrowUp />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {column.canResize && (
                    <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? "isResizing" : ""}`}/>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="tbody">
          {rows?.length === 0 && (
            <tr className="no_data_found" style={{ textAlign: "center", display: "flex", justifyContent: "center" }} >
              <td colSpan={columns?.length}>
                {NoDataComponent}
              </td>
            </tr>
          )}
          {page.map((row, rInd) => {
            prepareRow(row);
            let params = { id: row.original.id, row: row.original };
            //console.log("prss ::",row.original.id);
            return (
              <tr data-key={'body_row' + rInd} key={'body_row' + rInd} {...row.getRowProps()} className={selectedIds.includes(row.original.id) ? "selected" : "tr"} >
                {row.cells.map((cell, index) => {
                  return (
                    <td key={row.original.id + 'body_row' + cell.value + index} {...cell.getCellProps(cellProps)}  className={`td${cell.column.id === 'settings' ? ' settingColumn' : ''}` }>
                      {cell.render("Cell")}
                      {cell.column.id === 'settings' && (
                        <div style={{ color: "white", size: 1000, cursor: 'pointer' , textAlign : 'end' }} className="setting">
                          <img style={{ height: "20px" }} src="/more sub nav.svg" alt="Settig" onClick={(e) => {
                            if(handleClickMenu){
                              handleClickMenu(e, params);
                            }
                            if(setCurrentFileId){
                              setCurrentFileId({ id: params.row.id, name: params.row.name, type: params?.row?.type ? params?.row?.type : "folder"});
                            }
                          }} />
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {(rows?.length === 0 || !pagination) ? (
        <></>
      ) : (
        <div style={{ background: 'white',display: "flex", justifyContent: "space-between", position: "fixed", bottom: "2px", width: "87%", padding: "0px 10px"}}>
          <div style={{ fontSize: 15, color: "#505050" ,marginLeft: bottom&&"23px" }}>
            Items per page
            <Select
              style={{ marginLeft: "20px", marginRight: "20px",cursor: 'pointer', height: bottom? "37px" :'25px'}}
              className= {"issuebottomLeft"}
              value={state.pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[100, 150, 200, 300].map((pageSize) => (
                <MenuItem key={pageSize} value={pageSize}>
                  {pageSize}
                </MenuItem>
              ))}
            </Select>
            {(state.pageSize * (state.pageIndex + 1)) - (state.pageSize) + 1 + " "}-{" " + (state.pageSize * (state.pageIndex + 1) < listCount ? state.pageSize * (state.pageIndex + 1) : listCount) + " "} of{" "}
            {listCount + " "} items
          </div>
          <div style={{ justifyContent: "flex-end", position: "relative", display: "flex", flexDirection: "row", alignItems:"baseline" }}>
            <div style={{ marginRight: "20px" }}>
              <div style={{ fontSize: 15, display: "flex" }}>
                <div className="hoverStyle"><img onClick={() => {
                handleGoToPage(0)
                }} style={{ filter: state.pageIndex > 0 ? 'invert(39%) sepia(0%) saturate(1741%) hue-rotate(147deg) brightness(79%) contrast(99%)' : "" }} src="/skip previous.svg" alt="e2" /></div> 
                <div style={{ color: !canPreviousPage ? "#BABABA" : "#505050", marginLeft: '15px' }} onClick={() => {
                  if(canPreviousPage){
                    handleGoToPage(state.pageIndex - 1);
                  }
                }} className="hoverStyle"> <img style={{ filter: canPreviousPage ? 'invert(39%) sepia(0%) saturate(1741%) hue-rotate(147deg) brightness(79%) contrast(99%)' : "", marginRight:'5px' }} src="/navigate previous.svg" alt="e" /> Previous</div>
              </div>
            </div>
            <div style={{ marginRight: "25px" }}>
              <input style={{ width:"35px"  }}
                type="number"
                className={"issuebottomRight"}
                value={state.pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                  handleGoToPage(pageNumber);
                }}
              />{" "}
              of {pageOptions.length}
            </div>
            <div>
              <div style={{ fontSize: 15, display: "flex" }}>
                <div style={{ color: !canNextPage ? "#BABABA" : "#505050", marginRight: '15px' }} onClick={() => {
                  if(canNextPage){
                    handleGoToPage(state.pageIndex + 1);
                  }
                }} className="hoverStyle" >
                  Next
                  <img style={{ filter: !canNextPage ? 'invert(82%) sepia(2%) saturate(0%) hue-rotate(52deg) brightness(93%) contrast(89%)' : "", marginLeft: '8px' }} src="/navigate next.svg" alt="e1" />
                </div>
                <div className="hoverStyle">
                  <img style={{ filter: state.pageIndex == pageCount - 1 ? 'invert(82%) sepia(2%) saturate(0%) hue-rotate(52deg) brightness(93%) contrast(89%)' : "" }}
                    onClick={() => {
                      handleGoToPage(pageCount - 1);
                    }} src="/skip next.svg" alt="e11" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
}
const NoData = ({errorMessage, searchBoxFilter}) => {
  return <div style={{ 'textAlign': 'center', 'paddingTop':searchBoxFilter ? "48px" : '200px' }}>{searchBoxFilter && <div> <img src="/form empty.svg" alt="empty"/></div>}<div>{errorMessage ? errorMessage:"No Data Found"}</div></div>;
};
function ReactDataTable({
  columns_react_table,
  rowss,
  handleClickMenu,
  openMenu,
  selectedIds,
  setCurrentFileId,
  listCount,
  setRowsPerPage,
  pagination,
  errorMessage,
  bottom,
  searchBoxFilter,
  initPageIndex,
  onPageChange,
}) {

  //console.log("reRender1");

  return (
    <Styles>
      <Table
        bottom={bottom}
        errorMessage={errorMessage}
        columns={columns_react_table}
        data={rowss}
        openMenu={openMenu}
        setCurrentFileId={setCurrentFileId}
        handleClickMenu={handleClickMenu}
        selectedIds={selectedIds}
        NoDataComponent={<NoData errorMessage={errorMessage} searchBoxFilter={searchBoxFilter}/>}
        listCount={listCount}
        setRowsPerPage={setRowsPerPage}
        pagination={pagination}
        initPageIndex={initPageIndex}
        onPageChange={onPageChange}
      />
    </Styles>
  );
}

export default ReactDataTable;