import React, { useState, useEffect, useRef, useReducer } from "react";
import { Button, Dialog, Tooltip } from "@mui/material";
import axios from "axios";
import { fetchUrl } from "Urls";
import { cloneDeep } from "lodash";
import moment from "moment";
import Spinner from "components/spinner";
import { toast } from "react-toastify";
import FormChecklistSectionCard from "./FormChecklistSectionCard";
import ChecklistFormPreviewExport from "./ChecklistFormPreviewExport";
import ToastComponent from "components/ToastComponent";
import CreateProjectForm from "components/FormsComponent/CreateProjectForm";
import { ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";
const submitFormDialog = {
  title: 'Submit Form?',
  content: 'Are you sure you want to submit this form? Once submitted, you won’t be able to make any changes.',
  action1: "Cancel",
  action2: "Submit Form"
};
const BuildChecklistForm = ({ projectFormId, credential, templateList, onCancel, ...props }) => {
    const [formId, setFormId] = useState(projectFormId);
    const [sectionCurrentListWithAns, dispatch] = useReducer(reducerF, []);
    const [selectedSection, setSelectedSection] = useState();
    const [disableSave, setDisableSave] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [disableSubmitError, setDisableSubmitError] = useState(true);
    const [checklistFormDetails, setChecklistFormDetails] = useState([]);
    const [sfObj, setsfObj] = React.useState(defaultObj());
    const sfObjRef = useRef(null);
    const [editFormDetails, setEditFormDetails] = useState(false);
    const [showSpinner, setSpinner] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [refreshOnClose, setRefreshOnClose] = useState(false);
    const [isFormCompleted, setIsFormCompletedState] = useState(false);
    const [userValidationMatch, setUserValidationMatch] = useState(false);
    const [showSubmitWarning, setShowSubmitWarning] = useState(false);
    const [exportFormPdf, setExportFormPdf] = useState(false);
    const [saveChanges, SetSaveChanges] = useState(false);
    const [sectionClicked, setSectionClicked] = useState(false);
    const isFormReadonly = useRef(null);
    function setIsFormCompleted(state) {
        isFormReadonly.current = state;
        setIsFormCompletedState(state);
    }
    var requiredMissing = useRef(null);
    function defaultObj() {
        //console.log('credential :: '+credential?.userList?.length);
        return {
            Project__c: credential.projectId,
            Status__c: props?.defaultStatus,
            Date__c: moment(new Date()).format("YYYY-MM-DD"),
        };
    }
    useEffect(() => {
        if (projectFormId) {
            getFormDetails(projectFormId);
        }
    }, []);
    async function getFormDetails(_formId = formId) {
        setSpinner(true);
        await axios.get(`${fetchUrl}/getProjectFormDetail/${_formId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
            .then((res) => {
                //console.log("count:::: " + JSON.stringify(res));
                if (res.status === 200) {
                    //{formObj : cObj , docDetail : docReturn}
                    if (res.data?.formObj) {
                        setIsFormCompleted(res.data?.formObj.Status__c == "Completed");
                        if(res.data?.formObj?.Ball_in_Court__c == credential.userId || res.data?.formObj?.CreatedById == credential.userId){
                            setUserValidationMatch(true);
                        }else{
                            setUserValidationMatch(false);  
                        }
                        setVariables(res.data.formObj);
                        if (res.data.docDetail) {
                            if (res.data.formObj.Form_Template__c) {
                                setChecklistFormDetails(res.data.docDetail);
                                dispatch({ type: "HANDLE_SECTION_REPLACE", sectionList: res.data.docDetail });
                            }
                        }
                        return;
                    }
                    setVariables(defaultObj());
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setSpinner(false);
    }
    function setVariables(_sfObj) {
        sfObjRef.current = _sfObj;
        if (_sfObj?.Id) {
            setFormId(_sfObj.Id);
        }
        requiredMissing.current = !checkReadyToSave(sfObjRef.current);
        console.log("_sfObj ::::", _sfObj);
        setsfObj(_sfObj);
    }
    function checkReadyToSave(_sfObj) {
        //console.log('checkReadyToSave');
        let reqfields = ["Form_Template__c", "Project__c", "Date__c", "Name"];
        if (_sfObj) {
            for (let f of reqfields) {
                if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
                    //console.log('checkReadyToSave :: '+f);
                    return false;
                }
            }
        }
        return true;
    }
    function reducerF(state, action) {
        //console.log('action.type :: '+action.type);
        switch (action.type) {
            case "HANDLE_SECTION_REPLACE":
                //replace current state with new state (action.sectionList)
                checklistSaveCheck(action.sectionList);
                return action.sectionList;
            case "HANDLE_CHANGE_ANS_FIELD":
                const tmpSec_ans = cloneDeep(state);
                const qArr_ans = tmpSec_ans[action.sectionIndex].items;
                //console.log('action.value :: ' + action.value);
                //console.log('ans[action.fieldKey] :: ' + qArr_ans[action.questionIndex].ans[action.fieldKey]);
                let jsB = action.value;
                if ((!action.value && action.value != 0) || action.value == null || action.value == "") {
                    jsB = null;
                }
                if (qArr_ans[action.questionIndex].ans[action.fieldKey] != jsB
                    && qArr_ans[action.questionIndex].ans[action.fieldKey] != action.value) {
                    //console.log('go');
                    qArr_ans[action.questionIndex].ans[action.fieldKey] = jsB;
                    if (!qArr_ans[action.questionIndex].ans.isSaving) {
                        qArr_ans[action.questionIndex].ans.isSaving = true;
                        saveSingleResponse(tmpSec_ans, action.sectionIndex, action.questionIndex);
                    }
                    checklistSaveCheck(tmpSec_ans);
                }
                return tmpSec_ans;
            case "HANDLE_CHANGE_SIGNATUREANS_FIELD":
                const tmpSec_anss = cloneDeep(state);
                const qArr_anss = tmpSec_anss[action.sectionIndex].items;

                // Apply the entire updated object to the state
                qArr_anss[action.questionIndex].ans = { ...qArr_anss[action.questionIndex].ans, ...action.updatedObject };

                if (!qArr_anss[action.questionIndex].ans.isSaving) {
                    qArr_anss[action.questionIndex].ans.isSaving = true;
                    saveSingleResponse(tmpSec_anss, action.sectionIndex, action.questionIndex);
                }

                checklistSaveCheck(tmpSec_anss);

                return tmpSec_anss;
            default:
                return state;
        }
    }
    async function saveSingleResponse(state, sectionInd, questionInd) {
        // let cloneFormData = JSON.parse(JSON.stringify(state));

        if (projectFormId) {
            const tmpSec_ans = cloneDeep(state);
            const qArr_ans = tmpSec_ans[sectionInd].items;
            qArr_ans[questionInd].ans.Form__c = projectFormId;
            const formData = new FormData();
            //let issueObj = sfIssueObj;
            formData.append("recObj", JSON.stringify(qArr_ans[questionInd].ans));
            console.log("formData ::", qArr_ans[questionInd].ans);
            await axios.post(`${fetchUrl}/recSave/Form_Response__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
                .then((res) => {
                    //console.log("count:::: " + JSON.stringify(res));
                    if (res.data.status == 200) {
                        setDisableSave(true);
                        qArr_ans[questionInd].ans.Id = res.data.retObj.id;
                        qArr_ans[questionInd].ans.isSaving = false;
                        dispatch({ type: "HANDLE_SECTION_REPLACE", sectionList: tmpSec_ans, });
                        if (sfObj.Status__c === 'Not Started') {
                            if (qArr_ans[questionInd].Optional__c === false) {
                                saveFormObjOnServer({ Id: projectFormId, Status__c: "In Progress", });
                            }
                        }
                        SetSaveChanges(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            //  setSpinner(false);
        } else {
            // need to save project form first
            setTimeout(function () {
                onClickSaveChecklist();
            }, 100);
        }
    }
    const onClickSaveChecklist = async (buttonType = "save", msg) => {
        SetSaveChanges(true);
        let toSave = sfObjRef.current;
        let formFieldStatus = await getAnsBasedStatus();
        let status_V = formFieldStatus.cal_status;
        let req_missing = formFieldStatus.reqMissing;
        let ansList = formFieldStatus.ansList;
        console.log("status_V 0 :: " + status_V);
        let process = true;
        if (buttonType == "save" || !buttonType) {
            if (status_V == "Completed") {
                status_V = "In Progress";
            }
        }
        console.log("status_V :: " + status_V);
        if (process) {
            setSpinner(true);
            toSave.Status__c = status_V;
            if(buttonType == "submit"){
                toSave.Ball_in_Court__c = toSave?.CreatedById ? toSave?.CreatedById : credential?.userId ;
                toSave.Status__c = 'Completed' ;
            }
            const sendObj = { checkObj: toSave, ansList: ansList };
            if (true) {
                const formData = new FormData();
                //let issueObj = sfIssueObj;
                formData.append("checklistData", JSON.stringify(sendObj));
                await axios.post(`${fetchUrl}/SaveCheckListForm?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
                    .then((res) => {
                        //console.log("count:::: " + JSON.stringify(res));
                        if (res.status === 200) {
                            toSave.Id = res.data.formId;
                            setVariables(toSave);
                            setRefreshOnClose(true);
                            if (msg) {
                                setToastMessage(msg);
                            }
                            if (buttonType == "complete") {
                                onClose(true);
                            } else {
                                getFormDetails(toSave.Id);
                            }
                            SetSaveChanges(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setSpinner(false);
                    });
            }
        }
    };
    const saveFormObjOnServer = async (formObj) => {
        const formData = new FormData();
        formData.append('recObj', JSON.stringify(formObj));
        await axios.post(`${fetchUrl}/recSave/Form__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
            .then(res => {
                if (res.data.status == 200) {
                    let fff = sfObj.current;
                    fff.Status__c = 'In Progress';
                    setVariables(fff);
                }
            }).catch(err => {
                console.log(`error /recSave/Form__c`, err);
            });
    };
    async function onClose(state = refreshOnClose) {
        onCancel(state);
    }
    async function checklistSaveCheck(state) {
        let formFieldStatus = await breakDownChecklistAns(state);
        setDisableSubmit(formFieldStatus.reqMissing);
        setDisableSubmitError(formFieldStatus.reqMissingCondition);
    }
    async function breakDownChecklistAns(_sectionCurrentListWithAns = sectionCurrentListWithAns) {
        let status_V = "Not Started";
        let ansList = [];
        let populatedCount = 0;
        let skipCount = 0;
        let reqCount = 0;
        let reqMissing = false;
        let reqMissingCondition = false;
        //console.log('totalFieldCount.size :: '+totalFieldCount);
        for (let sec of _sectionCurrentListWithAns) {
            for (let item of sec.items) {
                const required = !item.Optional__c;
                const requiredCondtion = item.Conditional_Logic__c;
                let populated = false;
                let skipCheck = !required;
                let value;
                if(requiredCondtion){
                    let checkResult = checkConditionalLogicBasedOnResponse(item);
                    if(checkResult.ifLogicFail){
                        if(!reqMissingCondition){
                            reqMissingCondition = true;
                        }
                    }
                    /*let filterResponse = item.responseOptions.filter((response) => response.value == item.ans.Response__c);
                    if (filterResponse && filterResponse[0] && filterResponse[0].action) {
                        if (filterResponse[0].action == "image") {
                            if (!item.ans.attachments) {
                                if(!reqMissingCondition){
                                    reqMissingCondition = true;
                                }
                            } 
                        } else if (filterResponse[0].action == "issue") {
                            if (!item.ans.issues) {
                                if(!reqMissingCondition){
                                    reqMissingCondition = true;
                              }
                            } 
                        } 
                    }*/
                }
                if (required) {
                    reqCount++;
                    value = item.ans.Response__c;
                    if (!(value === undefined || value == "" || value == null)) {
                        populated = true;
                    }
                    value = item.ans.Signature__c;
                    if (
                        populated == false &&
                        !(value === undefined || value == "" || value == null)
                    ) {
                        populated = true;
                    }
                    if (!reqMissing && !populated) {
                        reqMissing = true;
                    }
                }
                if (item.ans) {
                    ansList.push(item.ans);
                }
                //console.log('value :: '+value , 'required ' ,required , 'populated ' , populated);
                if (populated || skipCheck) {
                    if (skipCheck) {
                        skipCount++;
                    }
                    populatedCount++;
                }
            }
        }
        let totalFieldCount = ansList.length;
        if (populatedCount == 0 || populatedCount - skipCount == 0) {
            status_V = "Not Started";
        } else if (populatedCount == totalFieldCount) {
            status_V = "Completed";
        } else if (populatedCount != 0) {
            status_V = "In Progress";
        }
        console.log('totalFieldCount :: ' + totalFieldCount, 'populatedCount ', populatedCount, 'skipCount ', skipCount, 'reqCount ', reqCount);
        //console.log(" ansList toSave  :: "+ JSON.stringify(ansList));
        return { cal_status: status_V, reqMissing: reqMissing, ansList: ansList, reqMissingCondition: reqMissingCondition };
    }
    async function getAnsBasedStatus() {
        return breakDownChecklistAns(sectionCurrentListWithAns);
    }
    function handleEditFormDetails(state, action) {
        setEditFormDetails(true)
    }
    const fullSectionFilledQuestions =(sectionItems)=>{
        let count = 0;
        let toCheckItems = sectionItems?.filter((itm) => itm.Optional__c === false || itm.Conditional_Logic__c === true);
        if(toCheckItems?.length > 0){
            for(let itm of toCheckItems){
                let reqFilled = itm.Optional__c === true;
                let condiFilled = itm.Conditional_Logic__c === false;
                if(reqFilled === false){
                    if((itm.ans.Response__c && itm.ans.Response__c !== "") || itm.ans.Signature__c){
                        reqFilled = true;
                    }
                }
                if(condiFilled === false && itm.ans.Response__c && itm.ans.Response__c !== ""){
                    let checkResult = checkConditionalLogicBasedOnResponse(itm);
                    condiFilled = !checkResult.ifLogicFail;
                }
                if(reqFilled && condiFilled){
                    count++;
                }
            }
        }
        return count;
    }
   
    return (
        <>
            <Dialog open={true} fullScreen>
                {(showSpinner)&& <Spinner />}
                    {sfObj && sfObj?.Name && <div className="builder">
                        <div className="builder_head" >
                            <div className="builder_head_left">
                                <div className="builder_head_left-container" onClick={() => onClose()}>
                                    <div>
                                        <img src="/left.svg" alt="leftIcon" />
                                    </div>
                                    <div className="header_label">
                                        Go to Forms
                                    </div>
                                </div>
                            </div>
                            <div className="builder_head_center">
                                <div className="header_label_dark">{sfObj.Name}</div>
                                {!isFormCompleted && userValidationMatch &&
                                    <div className="clickable custom_button" onClick={handleEditFormDetails}>
                                        <img src="/Pencil.svg" alt="leftIcon" />
                                    </div>
                                }
                                {saveChanges ? (
                                    <div style={{ color: "#929292", fontSize: "14px", fontWeight: 400 }}>
                                        Saving...
                                    </div>
                                ) : (
                                    <div style={{ color: "#929292", fontSize: "14px", fontWeight: 400 }}>
                                        All Changes Saved
                                    </div>
                                )}
                            </div>
                            <div className="builder_head_right">
                                <div className="header_icon" style={{ border: (isFormCompleted || !userValidationMatch) && "none" }}>
                                    {!isFormCompleted && userValidationMatch &&
                                        <img style={{ cursor: "pointer", marginRight: "12px" }} src="/copy.svg" alt="leftIco" />
                                    }
                                    <img style={{ cursor: "pointer" }} src="/download.svg" alt="leftIco" onClick={() => {setExportFormPdf(true);
                                              toast.info("Download started");
                                    }} />
                                </div>
                                {!isFormCompleted && userValidationMatch &&
                                    <>
                                        <div>
                                            <Button className="custom_button cancel_button" onClick={() => { onClickSaveChecklist('save', `${sfObj.Name} has been updated.`); }}>Save Changes</Button>
                                        </div>
                                        <div>
                                            <Button className="custom_button apply_button" disabled={(disableSubmit || disableSubmitError)} onClick={() => { setShowSubmitWarning(true); }}>
                                                Submit Form
                                            </Button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        {disableSubmit && userValidationMatch &&
                            <div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "red", height: "48px", borderBottom: "1px solid #D8D8D8" }}>Please complete the checklist below</div>
                            </div>
                        }
                        {isFormCompleted &&
                            <div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#346EF5", height: "48px", borderBottom: "1px solid #D8D8D8" }}>Form is in review</div>
                            </div>
                        }
                        <div className="builder_body" style={{ justifyContent: "flex-start" }}>
                            <div className="builder_body_left" style={{ width: (isFormCompleted || !userValidationMatch) && "40%" }}>
                                <div className="builder_body_left-container">
                                    {userValidationMatch &&
                                        <>
                                            <div className="header_label_dark">
                                                Form Outline
                                            </div>
                                            <div className="builder_body_left-container_list" style={{ height: isFormCompleted ?  "40vh" : "83vh"}}>
                                                {sectionCurrentListWithAns && sectionCurrentListWithAns.map((section, index) => {
                                                    const filledCount = fullSectionFilledQuestions(section?.items);
                                                    return (
                                                        <div className={`builder_body_left-container_list-item  ${selectedSection === section.identifier ? 'section_list_selected' : ''}`} key={section.identifier}>
                                                            <div className="default_text text_no-wrap" style={{ width: "80%" }} onClick={() => {setSelectedSection(section.identifier)
                                                            if(selectedSection != section.identifier){
                                                                setSectionClicked(true);
                                                            }
                                                            }}>
                                                                <Tooltip title={section.Name && section.Name.trim() != '' ? section.Name : 'Untitled Section'} placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow>
                                                                    {section.Numbering_Sequence__c}&nbsp;&nbsp;{section.Name && section.Name.trim() != '' ? section.Name : 'Untitled Section'}
                                                                </Tooltip>
                                                            </div>
                                                            <div style={{ width: "10%", marginLeft: 'auto' }}>
                                                                <>{filledCount}/{(section?.items?.filter((itm) => itm.Optional__c === false || itm.Conditional_Logic__c === true)).length}</>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                    {(isFormCompleted || !userValidationMatch) &&
                                        <div className="section">
                                            <div className="section_head">
                                                <div>Form Details</div>
                                            </div>
                                            <div className="section_body">
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Id</div>
                                                    <div style={{ color: "#000000" }}>{sfObj?.Numbering_Sequence__c}</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Form Name</div>
                                                    <div style={{ color: "#000000" }}>{sfObj?.Name}</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Form Type</div>
                                                    <div style={{ color: "#000000" }}>{sfObj?.Form_Template__r?.Form_Type__c}</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Category</div>
                                                    <div style={{ color: "#000000" }}>{sfObj?.Form_Template__r?.Template_Category__c}</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Description</div>
                                                    <div style={{ color: "#000000" }}>{sfObj?.Description__c}</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Status</div>
                                                    <div style={{ color: "#000000" }}>  <img style={{ marginRight: "5px" }} src={sfObj?.Status__c == "Completed" ? "/closed-green-1.svg"
                                                        : sfObj?.Status__c == "In Progress" ? "/formsInProgess.svg"
                                                            : "/FormsNotStarted.svg"} alt="statusIcons" />{sfObj?.Status__c}</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Location</div>
                                                    <div style={{ color: "#000000" }}>-</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Ball in court</div>
                                                    <div style={{ color: "#000000" }}><img style={{ width: "20px", marginRight: "5px" }} src="/Ellipse 2.svg" alt="saaa" /> {sfObj?.Ball_in_Court__r?.Name}</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Due date</div>
                                                    <div style={{ color: "#000000" }}>{moment(sfObj?.Date__c).format('MM/DD/YYYY')}</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Last Updated By</div>
                                                    <div style={{ color: "#000000" }}><img style={{ width: "20px", marginRight: "5px" }} src="/Ellipse 2.svg" alt="saaa" /> {sfObj?.LastModifiedBy?.Name}</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Last Updated</div>
                                                    <div style={{ color: "#000000" }}>{moment(sfObj?.LastModifiedDate).format('MM/DD/YYYY')}</div>
                                                </div>
                                                <div className="formDetail">
                                                    <div className="formDetail_heading">Created By</div>
                                                    <div style={{ color: "#000000" }}><img style={{ width: "20px", marginRight: "5px" }} src="/Ellipse 2.svg" alt="saaa" /> {sfObj?.CreatedBy?.Name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className="builder_body_center" style={{ position: "relative", height: isFormCompleted ? "87vh" : disableSubmit ? "87vh" : "91vh" }}>
                                <FormChecklistSectionCard
                                    formId={projectFormId}
                                    setSelectedSection={setSelectedSection}
                                    setSectionClicked={setSectionClicked}
                                    sectionClicked={sectionClicked}
                                    selectedSection={selectedSection}
                                    credential={credential}
                                    checklistFormDetails={sectionCurrentListWithAns}
                                    dispatchObj={dispatch}
                                    formObj={sfObj}
                                    isFormCompleted={isFormCompleted}
                                    userValidationMatch={userValidationMatch}
                                    SetSaveChanges={SetSaveChanges}
                                />
                            </div>
                            {editFormDetails &&
                                <CreateProjectForm
                                    sfFormDetail={sfObj}
                                    templateOptions={templateList}
                                    credential={credential}
                                    currentStep={2}
                                    onCancel={(refresh, redirectId) => {
                                        setEditFormDetails(false);
                                    }}
                                    onAccept={async (sfreocrd) => {
                                        //console.log('sfreocrd ::', sfreocrd);
                                        setSpinner(true);
                                        let rec = cloneDeep(sfreocrd);
                                        if (sfreocrd?.Id) {
                                            delete rec.Form_Template__c;
                                        }
                                        setEditFormDetails(false);
                                        setVariables(sfreocrd);
                                        const formData = new FormData();
                                        //console.log("rec ::", rec);
                                        formData.append("recObj", JSON.stringify(rec));
                                        await axios.post(`${fetchUrl}/recSave/Form__c`, formData)
                                            .then(async (res) => {
                                                //console.log("count:::: " + JSON.stringify(res));
                                                setSpinner(false);
                                                if (res.data.status === 200) {
                                                    sfreocrd.Id = res.data.retObj.id;
                                                    setVariables(sfreocrd);
                                                    setToastMessage('Form Details has been updated.');
                                                    setRefreshOnClose(true);
                                                    getFormDetails(sfreocrd.Id);
                                                }
                                            }).catch((err) => {
                                                console.log('recSave/Form__c', err);
                                                setSpinner(false);
                                            });
                                    }}
                                />
                            }
                        </div>
                    </div>}
                {exportFormPdf &&
                    <ChecklistFormPreviewExport
                        onCancel={() => {
                            setExportFormPdf(false)
                            let msg = `${sfObj.Name+" form"} download completed.`
                            toast.success(msg);
                        }}
                        FormIds={[sfObj.Id]}
                        credential={credential}
                        action={"single"}
                    />
                }
            </Dialog>
            {showSubmitWarning &&
                <ConfirmDialog
                DialogData={submitFormDialog}
                onAccept={async () => {
                    setShowSubmitWarning(false);
                    onClickSaveChecklist('submit' , `${sfObj.Name} has been submitted for review.` );
                }}
                onCancel={() => {
                    setShowSubmitWarning(false);
                }}
                />
            }
            {toastMessage &&
                <ToastComponent message={toastMessage} handleClose={() => setToastMessage(null)} />
            }
        </>
    );
    
}
export const checkConditionalLogicBasedOnResponse =(item)=>{
    let returnResult = {ifLogicFail : false };
    let filterResponse = item.responseOptions.filter((response) => response.value == item.ans.Response__c);
    if (filterResponse && filterResponse[0] && filterResponse[0].action) {
        if (filterResponse[0].action == "image") {
            if (!item.ans.attachments) {
                returnResult.ifLogicFail = true;
                returnResult.msg = 'Photo required based on the response';
            } 
        } else if (filterResponse[0].action == "issue") {
            if (!item.ans.issues) {
                returnResult.ifLogicFail = true;
                returnResult.msg = 'Issue required based on the response';
            } 
        } 
    }
    return returnResult;
}
export default BuildChecklistForm;