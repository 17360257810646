import React, { useState, useEffect, useRef } from "react";
import { Dialog , Button, Tooltip} from "@mui/material";
import styled from "styled-components";
import WebViewer from "@pdftron/webviewer";
import Draggable from "react-draggable";
import SearchIcon from "../../assets/images/search.svg";
import { ConfirmDialogCheckbox } from "components/DrawerComponent/DisciplineSettingDialog";
import { APRYSE_L_KEY } from "api" ;
const Style = styled.div`
  .sheet-file-view {
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #eaeaea;
    height: 56px;
    left: 0;
    display: flex;
    position: relative;
    justify-content: space-between;
    top: 0;
    width: 100%;
  }
  .sheet-name-and {
    display: inline-flex;
    gap: 12px;
    left: 20px;
    padding: 3px 0px;
    position: relative;
    top: 19px;
  }

  .text-wrapper-2 {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.14px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }

  .p {
    color: #505050;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.14px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  .Buttons {
    gap: 12px;
    display: flex;
    padding: 8px 0px;
    position: relative;
    //top: 19px;
    right: 20px;
  }
  .zoom-container {
    right: 15px;
    bottom: 15px;
    float: right;
    display: inline-flex;
    align-items: flex-start;
    gap: -1px;
  }
  .zoom-button {
    max-width: none !important;
    display: flex;
    height: 37px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid var(--neutrals-light-grey-3, #d8d8d8);
    background: var(--neutrals-white, #fff);
  }
`;
function ViewDeleteSheet({ ...props }) {
  const {
    onAccept,
    setViewSheet,
    onCancel,
    fileData,
    DialogData,
    credential,
    skipRestorePopup
  } = props;
  //console.log("fileData ::", fileData);
  const [showZoomActions, setShowZoomAction] = useState(false);
  const [restorePopup, setRestorePopup] = useState(false);
  const [searchInPdf, setSearchInPdf] = useState(false);
  const [instance, setInstance] = useState();
  const viewer = useRef();
  useEffect(() => {
    const inter = setTimeout(async () => {
      loadDocument();
    }, 1000);
  }, []);
  function handleSearchInPdf(ann, type) {
    let pdfSearchButton;
    pdfSearchButton = instance.UI.iframeWindow.document.querySelector('[data-element="searchButton"]');
    pdfSearchButton.click();
    setSearchInPdf(!searchInPdf);

  }
  const loadDocument = async () => {
    //console.log('viewer.curre nt :: ',viewer.current);

    const disElement = [
      "toolbarGroup-View",
      "toolsHeader",
      "toolbarGroup-Annotate",
      "toolbarGroup-Shapes",
      "toolbarGroup-Insert",
      "toolbarGroup-Measure",
      "toolbarGroup-Edit",
      "toolbarGroup-FillAndSign",
      "toolbarGroup-Forms",
      "toolbarGroup-Redact",
      "searchPanelResizeBar",
    ];
    await WebViewer(
      {
        fullAPI: true,
        path: "/webviewer/public",
        initialDoc: fileData.url,
        disabledElements: disElement,
        licenseKey: APRYSE_L_KEY, // sign up to get a free trial key at https://dev.apryse.com,
      },
      viewer.current
    ).then(async (instance) => {
      instance.UI.disableElements(disElement);
      const { documentViewer, annotationManager, Tools, PDFNet } =
        instance.Core;
        const iframeWindow = instance.UI.iframeWindow;
        const header = iframeWindow.document.querySelector('[data-element="header"]');
        header.style.display = 'none';
        setInstance(instance);
      documentViewer.addEventListener("documentLoaded", async () => {
        setShowZoomAction(true);
      });
    });
  };
  return (
    <Dialog open={true} fullScreen>
      <Style>
        <div className="sheet-file-view">
          <div className="sheet-name-and">
            <div className="text-wrapper-2">{fileData.name}</div>
            <p className="p">{fileData.set ? fileData.set : fileData.Shop_Drawing_Type__c}</p>
          </div>
          <div className="Buttons">
            {showZoomActions &&
              <div className="define-sheet-number-area body-regular" onClick={handleSearchInPdf} style={{ backgroundColor: searchInPdf && "#E8EFFF", alignSelf: "center" }}>
                <Tooltip title="Search" placement="bottom">
                  <img src={SearchIcon} alt="search_icon" />
                </Tooltip>
              </div>
            }
            <Button className="custom_button cancel_button" onClick={onCancel}>
              Cancel
            </Button>
            <Button className="custom_button apply_button" onClick={() => !skipRestorePopup ? setRestorePopup(true) : onAccept()}>
              Restore
            </Button>
          </div>
        </div>
        <div
          id="extractPDFcontainer"
          className="webviewer"
          ref={viewer}
          style={{ width: "100%", height: "94vh" }}
        />
        {showZoomActions && (
          <Draggable>
            <div className="zoom-container" style={{ position: "absolute" }}>
              <div id="zoom-out-button" class="zoom-button">
                100%{" "}
                <img style={{ marginLeft: "3px" }} src="down.svg" alt="100%" />
              </div>
              <div id="zoom-out-button" class="zoom-button">
                <img
                  src="remove.svg"
                  style={{ maxWidth: "none" }}
                  alt="zoomOut"
                />
              </div>
              <div id="zoom-in-button" className="zoom-button">
                <img src="add.svg" style={{ maxWidth: "none" }} alt="ZoomIn" />
              </div>
            </div>
          </Draggable>
        )}
        {restorePopup &&
          <ConfirmDialogCheckbox
            credential={credential}
            nameOfDontShowProperty='recycleSkipRestoreDialog'
            onAccept={() => {
              onAccept();
            }}
            onCancel={() => {
              setRestorePopup(false);
            }}
            DialogData={DialogData}
          />
        }
      </Style>
    </Dialog>
  );
}
export default ViewDeleteSheet;
